<template>
  <transition appear name="fade-out">
    <aside :class="['show-seasons', { active: btnActive }]">
      <button class="show-seasons__btn" v-on:click="activateDriverStandings">
        <span class="show-seasons__btn-text">Relative season standings</span>
        <svg
          :class="['show-seasons__svg', { active: !seasonsLoading }]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22.14 31.49"
        >
          <path
            d="M21.81 21.2c.44-.43.44-1.14 0-1.59-.43-.43-1.14-.43-1.57 0l-8.05 8.05V1.11C12.19.49 11.7 0 11.08 0S9.95.49 9.95 1.11v26.55l-8.03-8.05c-.44-.43-1.16-.43-1.59 0-.44.44-.44 1.16 0 1.59l9.95 9.95c.43.44 1.14.44 1.57 0l9.96-9.95z"
          />
        </svg>
        <div
          :class="['spinner spinner--show-seasons', { active: seasonsLoading }]"
        >
          <div class="spinner__inner"></div>
        </div>
      </button>
    </aside>
  </transition>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'show-seasons',
  data () {
    return {
      btnActive: true,
      seasonsLoading: false
    }
  },
  mounted () {
    // Emitted from DriverStandings.vue
    EventBus.$on('seasonsLoaded', () => {
      this.seasonsLoading = false
      this.btnActive = false
      EventBus.$off('seasonsLoaded')
    })
  },
  methods: {
    activateDriverStandings () {
      this.seasonsLoading = true

      // Emitted to DriverStandings.vue
      EventBus.$emit('activateDriverStandings')
    }
  }
}
</script>

<style scoped>
.fade-out-leave-to {
  opacity: 0;
}

.fade-out-leave-active {
  transition: opacity 0.15s ease-out, height 0.15s ease-out;
}
</style>
