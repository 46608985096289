<template>
  <aside :class="['race-select', { active: raceOverlayActive || yearOverlayActive }]">
    <div class="race-select__inner" :style="{ height: innerHeight }" ref="inner">
      <div :class="['race-select__overlay race-select__overlay--season',{ active: yearOverlayActive }]" ref="overlayYear">
        <header class="race-select__header">
          <div class="race-select__heading heading heading--small">Season</div>
          <button class="race-select__close" @click="closeYearSelect()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.485252 10.485292">
              <path d="M.2929.2929c-.3905.3905-.3905 1.0237 0 1.4142l3.5356 3.5355L.2929 8.7782c-.3905.3905-.3905 1.0237 0 1.4142.3905.3905 1.0237.3905 1.4142 0l3.5356-3.5356 3.5355 3.5356c.3905.3905 1.0237.3905 1.4142 0 .3905-.3906.3905-1.0237 0-1.4142L6.6569 5.2426l3.5355-3.5355c.3905-.3905.3905-1.0237 0-1.4142-.3905-.3905-1.0237-.3905-1.4142 0L5.2427 3.8284 1.707.293C1.3166-.0976.6834-.0976.293.2929z" />
            </svg>
          </button>
        </header>
        <div class="race-select__grid">
          <template v-for="(season, index) in validSeasons">
            <button class="race-select__season" :key="season[index]" v-on:click="seasonSelected(season.season, index)">{{ season.season }}</button>
          </template>
        </div>
      </div>
      <div :class="['race-select__overlay', { active: raceOverlayActive }]" ref="overlayRace">
        <header class="race-select__header">
          <div class="race-select__heading heading heading--small">Season: {{ activeSeason }}</div>
          <button class="race-select__close" @click="closeRaceSelect()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.485252 10.485292">
              <path d="M.2929.2929c-.3905.3905-.3905 1.0237 0 1.4142l3.5356 3.5355L.2929 8.7782c-.3905.3905-.3905 1.0237 0 1.4142.3905.3905 1.0237.3905 1.4142 0l3.5356-3.5356 3.5355 3.5356c.3905.3905 1.0237.3905 1.4142 0 .3905-.3906.3905-1.0237 0-1.4142L6.6569 5.2426l3.5355-3.5355c.3905-.3905.3905-1.0237 0-1.4142-.3905-.3905-1.0237-.3905-1.4142 0L5.2427 3.8284 1.707.293C1.3166-.0976.6834-.0976.293.2929z" />
            </svg>
          </button>
        </header>
        <div class="race-select__grid">
          <template v-for="(race, index) in validRaces">
            <button :key="race[index]" v-on:click="raceRouting(index + 1)">
              {{ race.raceName }}
            </button>
          </template>
          <template v-for="(race, index) in invalidRaces">
            <button class="race-select__invalid" v-on:click="raceRouting(index + 1)" :key="race[index]">
              {{ race.raceName }}
              <br />
              <p>{{ race.date }}</p>
            </button>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="race-select__wipe">
      <div class="race-select__gradient" ref="gradient"></div>
    </div>-->
  </aside>
</template>
<script>
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'race-select',
  props: {
    race: [Object, Boolean],
    seasons: Array,
    latestRaceData: [Object, Boolean]
  },
  data () {
    return {
      seasonsFetched: false,
      activeSeason: '',
      activeRace: '',
      activeSeasonIndex: '',
      raceOverlayActive: false,
      yearOverlayActive: false,
      raceOverlayHeight: '',
      yearOverlayHeight: '',
      innerHeight: '',
      validRaces: [],
      invalidRaces: [],
      validSeasons: []
    }
  },
  created () {
    // Emitted from RaceHeader.vue
    EventBus.$on('raceSelectYearState', () => {
      this.raceSelectYearState()
    })

    // Emitted from RaceHeader.vue
    EventBus.$on('raceSelectResetSeason', () => {
      this.resetSeason()
    })

    // Set current race and year
    this.activeRace = this.race.round
    this.activeSeason = this.race.season

    // Emitted from RaceHeader.vue
    EventBus.$on('raceSelectRaceState', (bool) => {
      this.raceSelectRaceState(bool)
    })
  },
  mounted () {
    // Used later for filtering race data
    const year = parseInt(process.env.VUE_APP_CURRENT_SEASON)
    if (this.$route.name === 'latestRace') {
      this.mostRecentSeason = parseInt(this.race.season)
      this.mostRecentRound = parseInt(this.race.round)
    } else if (this.$route.name === 'otherRaces') {
      this.mostRecentRound = parseInt(this.latestRaceData.round)
      this.mostRecentSeason = parseInt(this.latestRaceData.season)
      if (year !== parseInt(this.latestRaceData.season)) {
        this.mostRecentRound = 1
        this.mostRecentSeason = parseInt(this.seasons[0].season)
      }
    }
    this.getCurrentYearRaces()
    this.seasonsFetched = true
    this.getValidSeasons()
    this.getValidRaces()
    this.getInvalidRaces()
  },
  methods: {
    closeRaceSelect () {
      // Emitted to RaceHeader.vue
      EventBus.$emit('closeRaceSelect')

      this.raceOverlayActive = false
      this.resetStyles()
      this.resetSeason()
    },
    closeYearSelect () {
      // Emitted to RaceHeader.vue
      EventBus.$emit('closeYearSelect')
      EventBus.$emit('openRaceSelect')

      this.yearOverlayActive = false
      this.resetStyles()
      this.resetSeason()
    },
    resetSeason () {
      setTimeout(() => {
        // Slight delay for the ske of the animation
        this.activeSeason = this.race.season
      }, 300)
    },
    raceSelectYearState () {
      if (this.$refs.overlayYear === undefined) return

      // get height and set state of race select year overlay
      const overlayHeight = this.$refs.overlayYear.offsetHeight
      this.yearOverlayHeight = `${overlayHeight}px`
      this.innerHeight = `${overlayHeight}px`
      this.raceOverlayActive = false
      !this.yearOverlayActive ? this.yearOverlayActive = true : this.yearOverlayActive = false

      // Reset styles
      if (!this.yearOverlayActive) {
        this.yearOverlayHeight = ''
        this.innerHeight = ''
      }

      // Emitted to App.vue
      EventBus.$emit('raceSelectOverlayProps', this.yearOverlayHeight, this.yearOverlayActive)
    },
    raceSelectRaceState (bool) {
      if (this.$refs.overlayRace === undefined) return

      // get height and set state of race select race overlay
      const overlayHeight = this.$refs.overlayRace.offsetHeight
      this.raceOverlayHeight = `${overlayHeight}px`
      this.innerHeight = `${overlayHeight}px`

      if (bool) {
        this.yearOverlayActive = false
        !this.raceOverlayActive ? this.raceOverlayActive = true : this.raceOverlayActive = false
      }

      this.resetStyles()

      // Emitted to App.vue
      EventBus.$emit('raceSelectOverlayProps', this.raceOverlayHeight, this.raceOverlayActive)
    },
    resetStyles () {
      // Reset styles
      if (!this.raceOverlayActive) {
        this.raceOverlayHeight = ''
        this.innerHeight = ''
      }
    },
    getCurrentYearRaces () {
      // Get index of current active season
      const findYear = this.seasons.map(function (e) { return e.season }).indexOf(this.activeSeason)
      this.activeSeasonIndex = findYear
    },
    seasonSelected (season, index) {
      this.activeSeason = season
      this.activeSeasonIndex = index

      // Emitted to RaceHeader.vue
      EventBus.$emit('closeYearSelect')
      EventBus.$emit('openRaceSelect')

      this.getValidRaces()
      this.getInvalidRaces()
      this.raceSelectRaceState(true)
    },
    raceRouting: function (i) {
      this.raceOverlayActive = false
      const path = `/${this.activeSeason}/${i}`

      // Don't run if same path
      if (this.$route.path !== path) {
        this.$router.push({
          name: 'otherRaces',
          params: {
            season: this.activeSeason,
            round: i
          }
        })
        // this.yearOverlayActive = false
        // this.raceOverlayActive = false

        // Emitted to App.vue
        EventBus.$emit('raceSelectOverlayProps', 'auto', false)

        // Remove events so they're not called multiple times on route change
        EventBus.$off('raceSelectYearState')
        EventBus.$off('raceSelectRaceState')
      }
    },
    getValidSeasons () {
      const seasons = this.seasons.filter((x) => {
        const season = parseInt(x.season)
        return season <= this.mostRecentSeason
      })
      this.validSeasons = seasons
    },
    getValidRaces () {
      const races = this.seasons[this.activeSeasonIndex].Races.filter((x) => {
        const round = parseInt(x.round)
        const activeYear = parseInt(this.activeSeason)
        if (activeYear === this.mostRecentSeason) {
          // Return only the latest race and previous races if active year is the current year
          return round <= this.mostRecentRound
        } else {
          // else return all
          return round
        }
      })
      this.validRaces = races
    },
    getInvalidRaces () {
      const races = this.seasons[this.activeSeasonIndex].Races.filter((x) => {
        const round = parseInt(x.round)
        const activeYear = parseInt(this.activeSeason)
        if (activeYear === this.mostRecentSeason) {
          // Return only the latest race and previous races if active year is the current year
          return round > this.mostRecentRound
        } else {
          return false
        }
      })
      this.invalidRaces = races
    }
  }
}
</script>
