<template>
  <table class="section-table__table">
    <tbody>
      <tr>
        <th>P</th>
        <th colspan="2">Team</th>
        <th class="right-align">Wins</th>
        <th class="right-align">Pts</th>
      </tr>
      <tr
        v-for="(result, index) in constructorStandingsData.ConstructorStandings"
        :key="result.index"
      >
        <td>
          {{ result.positionText }}
          <span
            v-html="
              getDelta(index, result.Constructor.constructorId, 'position')
            "
            v-if="!constructorStandingsPreviousDataFetching"
          >
          </span>
        </td>
        <td class="section-table__flag">
          <img :src="getFlagImgUrl(index)" alt />
          <span class="section-table__nationality">{{
            result.Constructor.nationality
          }}</span>
        </td>
        <td class="section-table__constructor">
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              :data-constructor="result.Constructor.name"
              :href="result.Constructor.url"
              >{{ result.Constructor.name }}</a
            >
          </span>
        </td>
        <td class="right-align" v-if="result.wins">{{ result.wins }}</td>
        <td class="right-align" v-if="result.points">
          <span
            v-html="getDelta(index, result.Constructor.constructorId, 'points')"
            v-if="!constructorStandingsPreviousDataFetching"
          ></span>
          {{ result.points }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'constructor-standings-table',
  props: {
    constructorStandingsData: [Object, Boolean]
  },
  data () {
    return {
      constructorStandingsPreviousData: {},
      constructorStandingsPreviousDataFetching: true
    }
  },
  mounted () {
    this.fetchPreviousData()
  },
  methods: {
    fetchPreviousData () {
      let controller = new AbortController()

      // Get previous round
      const prevRound = this.constructorStandingsData.round - 1

      // If previous doesn't exist (i.e. current round is the first round) then return
      if (prevRound === 0) return

      prevRound.toString()

      const season = this.constructorStandingsData.season

      this.request = `https://ergast.com/api/f1/${season}/${prevRound}/constructorStandings.json`
      this.options = {
        method: 'GET',
        signal: controller.signal
      }

      fetch(this.request, this.options)
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for constructor standings data failed: ', err))
        .then((response) => response.json()
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(`'${err}' happened!`)
            return {}
          }))
        .then((json) => {
          if (json.status === 504 && this.$route.name === 'otherRaces') {
            // Abort fetch request before it has completed then use cached data
            controller.abort()
            controller = new AbortController()
            return fetch(this.request, {
              cache: 'force-cache',
              mode: 'same-origin',
              signal: controller.signal
            })
          }
          // Clear any previously stored data
          this.constructorStandingsPreviousData = false

          // Set data
          this.constructorStandingsPreviousData = json.MRData.StandingsTable.StandingsLists[0]
          this.constructorStandingsPreviousDataFetching = false
        })
    },
    getDelta (i, constructorId, type) {
      // debugger
      if (this.constructorStandingsPreviousData.ConstructorStandings[i]) {
        const prevConstructorIndex = this.constructorStandingsPreviousData.ConstructorStandings.findIndex(elem => {
          return elem.Constructor.constructorId === constructorId
        })
        const latestConstructorIndex = this.constructorStandingsData.ConstructorStandings.findIndex(elem => {
          return elem.Constructor.constructorId === constructorId
        })
        if (prevConstructorIndex >= 0) {
          const prevPos = parseInt(this.constructorStandingsPreviousData.ConstructorStandings[prevConstructorIndex][type])
          const latestPos = parseInt(this.constructorStandingsData.ConstructorStandings[latestConstructorIndex][type])
          this.posDelta = latestPos - prevPos

          if (this.posDelta !== 0) {
            if (this.posDelta < 0) {
              if (type === 'position') {
                this.deltaClassName = 'section-table__delta section-table__delta--position section-table__delta--pos'
                this.posDelta = this.posDelta.toString().replace(/-/g, '')
              } else if (type === 'points') {
                this.deltaClassName = 'section-table__delta section-table__delta--points section-table__delta--neg'
                this.posDelta = `+${this.posDelta.toString()}`
              }
            } else {
              if (type === 'position') {
                this.deltaClassName = 'section-table__delta section-table__delta--position section-table__delta--neg'
              } else if (type === 'points') {
                this.deltaClassName = 'section-table__delta section-table__delta--points section-table__delta--pos'
                this.posDelta = `+${this.posDelta.toString()}`
              }
            }
            return `<span class="${this.deltaClassName}">${this.posDelta}<span>`
          }
        }
      }
    },
    getFlagImgUrl (i) {
      const fallback = require.context('@/assets/img/', false, /\.png$/)
      const flagImages = require.context('@/assets/img/flags/', false, /\.svg$/)
      let flagImage = ''
      try {
        flagImage = flagImages(`./${this.constructorStandingsData.ConstructorStandings[i].Constructor.nationality}.svg`)
      } catch (error) {
        flagImage = fallback('./fallback.png')
      }
      return flagImage
    }
  }
}
</script>
