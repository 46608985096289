<template>
<table class="section-table__table">
  <tbody>
    <tr>
      <th>
        <button :class="[
              'section-table__sortable section-table__sortable--chevron-right section-table__position',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'position',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'position',
              },
            ]" @click="sortPosition">
          P
        </button>
      </th>
      <th>
        <button :class="[
              'section-table__sortable section-table__sortable--chevron-right section-table__grid',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'driverId',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'driverId',
              },
            ]" @click="sortDriver">
          Name
        </button>
      </th>
      <th>
        <button :class="[
              'section-table__sortable section-table__grid',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'team',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'team',
              },
            ]" @click="sortTeam">
          Team
        </button>
      </th>
      <th class="right-align">
        <button :class="[
              'section-table__sortable section-table__grid',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'grid',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'grid',
              },
            ]" @click="sortGrid">
          Grid
        </button>
      </th>
      <th class="right-align">
        <button :class="[
              'section-table__sortable section-table__grid-diff',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'gridDiff',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'gridDiff',
              },
            ]" @click="sortGridDiff">
          <span class="section-table__sortable section-content__icon-text">D<span class="show-gt-xs">elta</span></span>
        </button>
      </th>
    </tr>
    <tr v-for="result in positionData" :key="result.index">
      <td>{{ result.positionText }}</td>
      <td>
        <span class="section-table__given-name">{{
            result.Driver.givenName
          }}</span>
        {{ result.Driver.familyName }}
      </td>
      <td class="section-table__constructor">
        <span :data-constructor="result.Constructor.name">{{
            result.Constructor.name
          }}</span>
      </td>
      <td class="right-align" v-if="result.grid">{{ result.grid }}</td>
      <td v-else>&nbsp;</td>
      <td :class="[
            {
              'section-table__grid-diff--neg ': gridChangeNeg(result.gridDiff),
            },
            { 'section-table__grid-diff--pos': gridChangePos(result.gridDiff) },
            'section-table__grid-diff right-align',
          ]">
        {{ result.gridDiff }}
      </td>
    </tr>
  </tbody>
</table>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'position-table',
  props: {
    raceResults: [Array, Boolean],
    currentSortDir: [String, Boolean],
    currentSort: [String]
  },
  data () {
    return {
      positionData: []
    }
  },
  mounted () {
    // Clone original data, but keep reactivity indepedant to new array
    this.positionData = this.raceResults.map(o => ({
      ...o
    }))

    // Default sorting
    EventBus.$once('sortResultsAscDesInit', 'position', true, this.positionData)
  },
  methods: {
    sortPosition: function (e) {
      EventBus.$emit('sortResultsAscDes', 'position', false, this.positionData)
    },
    sortGrid: function (e) {
      EventBus.$emit('sortResultsAscDes', 'grid', false, this.positionData)
    },
    sortTeam: function (e) {
      EventBus.$emit('sortResultsAscDes', 'team', false, this.positionData)
    },
    sortDriver: function (e) {
      EventBus.$emit('sortResultsAscDes', 'driverId', false, this.positionData)
    },
    sortGridDiff: function (e) {
      EventBus.$emit('sortResultsAscDes', 'gridDiff', false, this.positionData)
    },
    gridChangeNeg (e) {
      if (e < 0) return true
    },
    gridChangePos (e) {
      if (e > 0) return true
    }
  }
}
</script>
