<template>
  <table class="section-table__table">
    <tbody>
      <tr>
        <th>
          <button :class="[
            'section-table__sortable section-table__sortable--chevron-right section-table__grid',
            {
              'active--desc':
                currentSortDir === 'asc' && currentSort === 'driverId',
              'active--asc':
                currentSortDir === 'desc' && currentSort === 'driverId',
            },
          ]" @click="sortDriver">
          Name
        </button>
        </th>
        <th class="right-align">
          <button
            :class="[
              'section-table__sortable ',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'lap',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'lap',
              },
            ]"
            @click="sortLap"
          >
            Lap
          </button>
        </th>
        <th class="right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'time',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'time',
              },
            ]"
            @click="sortTime"
          >
            Time
          </button>
        </th>
        <th class="right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'speed',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'speed',
              },
            ]"
            @click="sortSpeed"
          >
            Top Speed
          </button>
        </th>
      </tr>
      <tr v-for="result in lapData" :key="result.index">
        <td class="section-table__name">
          <span
            :data-constructor="result.Constructor.name"
            :data-driver="`${result.Driver.givenName} ${result.Driver.familyName}`"
          >
            <span class="section-table__given-name">{{
              result.Driver.givenName
            }}</span>
            {{ result.Driver.familyName }}
          </span>
        </td>
        <template v-if="result.FastestLap">
          <td class="right-align">{{ result.FastestLap.lap || "" }}</td>
          <td class="right-align">{{ result.FastestLap.Time.time || "" }}</td>
          <td class="right-align">
            {{
              result.FastestLap.AverageSpeed.speed +
                result.FastestLap.AverageSpeed.units || ""
            }}
          </td>
        </template>
        <template v-else>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'lap-table',
  props: {
    raceResults: [Array, Boolean],
    currentSortDir: [String, Boolean],
    currentSort: [String]
  },
  data () {
    return {
      lapData: []
    }
  },
  mounted () {
    // Clone original data, but keep reactivity indepedant to new array
    this.lapData = this.raceResults.map(o => ({
      ...o
    }))

    // Default sorting
    EventBus.$once('sortResultsAscDesInit', 'time', true, this.lapData)
  },
  methods: {
    sortLap: function (e) {
      EventBus.$emit('sortResultsAscDes', 'lap', false, this.lapData)
    },
    sortDriver: function (e) {
      EventBus.$emit('sortResultsAscDes', 'driverId', false, this.lapData)
    },
    sortTime: function (e) {
      EventBus.$emit('sortResultsAscDes', 'time', false, this.lapData)
    },
    sortSpeed: function (e) {
      EventBus.$emit('sortResultsAscDes', 'speed', false, this.lapData)
    }
  }
}
</script>
