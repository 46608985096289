<template>
  <table class="section-table__table">
    <tbody>
      <tr>
        <th>P</th>
        <th colspan="2">Name</th>
        <th class="section-table__heading--team">Team</th>
        <th class="right-align">Wins</th>
        <th class="right-align">Pts</th>
      </tr>
      <tr
        v-for="(result, index) in driverStandingsData.DriverStandings"
        :key="result.index"
      >
        <td>
          {{ result.positionText }}
          <span
            v-html="getDelta(index, result.Driver.driverId, 'position')"
            v-if="!driverStandingsPreviousDataFetching"
          ></span>
        </td>
        <td class="section-table__flag">
          <img :src="getFlagImgUrl(index)" alt />
          <span class="section-table__nationality">{{
            result.Driver.nationality
          }}</span>
        </td>
        <td class="section-table__name">
          <a
            :data-constructor="constructorsNameLastCount(result.Constructors)"
            target="_blank"
            rel="noopener noreferrer"
            :data-driver="`${result.Driver.givenName} ${result.Driver.familyName}`"
            :href="`${result.Driver.url}`"
          >
            <span class="section-table__given-name">{{
              result.Driver.givenName
            }}</span>
            {{ result.Driver.familyName }}
          </a>
        </td>
        <td class="section-table__constructor">
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              :data-constructor="constructorsNameLastCount(result.Constructors)"
              :href="constructorsUrlLastCount(result.Constructors)"
              >{{ constructorsNameLastCount(result.Constructors) }}</a
            >
          </span>
        </td>
        <td class="right-align" v-if="result.wins">{{ result.wins }}</td>
        <td class="right-align" v-if="result.points">
          <span
            v-html="getDelta(index, result.Driver.driverId, 'points')"
            v-if="!driverStandingsPreviousDataFetching"
          ></span>
          {{ result.points }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'driver-standings-table',
  props: {
    driverStandingsData: [Object, Boolean],
    driverStandingsDataFetching: Boolean
  },
  data () {
    return {
      driverStandingsPreviousData: {},
      driverStandingsPreviousDataFetching: true
    }
  },
  mounted () {
    this.fetchPreviousData()
  },
  methods: {
    getFlagImgUrl (i) {
      const fallback = require.context('@/assets/img/', false, /\.png$/)
      const flagImages = require.context('@/assets/img/flags/', false, /\.svg$/)
      let flagImage = ''
      try {
        flagImage = flagImages(`./${this.driverStandingsData.DriverStandings[i].Driver.nationality}.svg`)
      } catch (error) {
        flagImage = fallback('./fallback.png')
      }
      return flagImage
    },
    constructorsNameLastCount (i) {
      // Get length and return last (to get most recent team)
      return i[i.length - 1].name
    },
    constructorsUrlLastCount (i) {
      // Get length and return last (to get most recent team)
      return i[i.length - 1].url
    },
    fetchPreviousData () {
      let controller = new AbortController()

      // Get previous round
      const prevRound = this.driverStandingsData.round - 1

      // If previous doesn't exist (i.e. current round is the first round) then return
      if (prevRound === 0) return

      prevRound.toString()

      const season = this.driverStandingsData.season

      this.request = `https://ergast.com/api/f1/${season}/${prevRound}/driverStandings.json`
      this.options = {
        method: 'GET',
        signal: controller.signal
      }

      fetch(this.request, this.options)
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for driver standings data failed: ', err))
        .then((response) => response.json()
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(`'${err}' happened!`)
            return {}
          }))
        .then((json) => {
          if (json.status === 504 && this.$route.name === 'otherRaces') {
            // Abort fetch request before it has completed then use cached data
            controller.abort()
            controller = new AbortController()
            return fetch(this.request, {
              cache: 'force-cache',
              mode: 'same-origin',
              signal: controller.signal
            })
          }
          // Clear any previously stored data
          this.driverStandingsPreviousData = false

          // Set data
          this.driverStandingsPreviousData = json.MRData.StandingsTable.StandingsLists[0]
          this.driverStandingsPreviousDataFetching = false
        })
    },
    getDelta (i, driverId, type) {
      // debugger
      if (this.driverStandingsPreviousData.DriverStandings[i]) {
        const prevDriverIndex = this.driverStandingsPreviousData.DriverStandings.findIndex(elem => {
          return elem.Driver.driverId === driverId
        })
        const latestDriverIndex = this.driverStandingsData.DriverStandings.findIndex(elem => {
          return elem.Driver.driverId === driverId
        })
        if (prevDriverIndex >= 0) {
          const prevPos = parseInt(this.driverStandingsPreviousData.DriverStandings[prevDriverIndex][type])
          const latestPos = parseInt(this.driverStandingsData.DriverStandings[latestDriverIndex][type])
          this.posDelta = latestPos - prevPos

          if (this.posDelta !== 0) {
            if (this.posDelta < 0) {
              if (type === 'position') {
                this.deltaClassName = 'section-table__delta section-table__delta--position section-table__delta--pos'
                this.posDelta = this.posDelta.toString().replace(/-/g, '')
              } else if (type === 'points') {
                this.deltaClassName = 'section-table__delta section-table__delta--points section-table__delta--neg'
                this.posDelta = `+${this.posDelta.toString()}`
              }
            } else {
              if (type === 'position') {
                this.deltaClassName = 'section-table__delta section-table__delta--position section-table__delta--neg'
              } else if (type === 'points') {
                this.deltaClassName = 'section-table__delta section-table__delta--points section-table__delta--pos'
                this.posDelta = `+${this.posDelta.toString()}`
              }
            }
            return `<span class="${this.deltaClassName}">${this.posDelta}<span>`
          }
        }
      }
    }
  }
}
</script>
