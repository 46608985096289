var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","name":"fade-in"}},[(_vm.qualifyingResults)?_c('section',{staticClass:"qualifying-results race-results section section--color3"},[_c('header',{staticClass:"section-content__header",on:{"mouseleave":_vm.deltaInfoOverlayHide}},[_c('h2',{ref:"heading",staticClass:"heading"},[_vm._v(_vm._s(this.qualifyingTableHeading))]),(!_vm.qualifyingPrevSeasonUnfetched)?_c('transition',{attrs:{"appear":"","name":"comparison-fade-in"}},[_c('span',{staticClass:"mini-sub-heading"},[_vm._v("Comparison: "+_vm._s(_vm.qualifying.season)+" / "+_vm._s(_vm.prevSeason))])]):_vm._e(),(_vm.$navigatorOnline)?_c('div',{staticClass:"section-content__icons"},[(this.prevSeason !== 2002 && _vm.qualifyingPrevSeasonUnfetched && _vm.qualifyingResultsPrevSeason)?_c('button',{staticClass:"section-content__icon",on:{"~click":function($event){return _vm.fetchPrevQualifyingData()}}},[_c('svg',{class:[
              'section-content__icon--previous-result',
              { inactive: _vm.comparisonLoading } ],attrs:{"viewBox":"-31 0 512 512","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"aria-label":'Previous event lap time comparison icon',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M225 512c124.066 0 225-101.383 225-226 0-60.824-24.078-119.578-70.36-163.43L405 97.215l19.395 19.39 21.21-21.21-60-60-21.21 21.21L383.785 76l-27.344 27.344C330.563 84.637 301.2 71.78 270 65.48V30h30V0H150v30h30v35.48C78.281 86.023 0 176.125 0 286c0 124.617 100.934 226 225 226zM210 30h30v31.504c-4.973-.324-9.973-.504-15-.504s-10.027.18-15 .504zm15 61c107.523 0 195 87.477 195 195 0 108.074-87.477 196-195 196S30 394.074 30 286c0-107.523 87.477-195 195-195zm0 0"}}),_c('path',{attrs:{"d":"M225 451c90.98 0 165-74.02 165-165s-74.02-165-165-165S60 195.02 60 286s74.02 165 165 165zM90.84 271c2.887-26 13.183-49.793 28.73-69.215l20.578 20.574 21.211-21.21-20.574-20.579C160.207 165.023 184 154.727 210 151.84V181h30v-29.16c26 2.887 49.793 13.183 69.215 28.73l-20.574 20.578 21.21 21.211 20.579-20.574C345.977 221.207 356.273 245 359.16 271H330v29.988h29.16c-2.887 26-13.183 49.805-28.73 69.227l-20.578-20.574-21.211 21.21 20.574 20.579C289.793 406.977 266 417.273 240 420.16V391h-30v29.16c-26-2.887-49.793-13.183-69.215-28.73l20.574-20.578-21.21-21.211-20.579 20.574C104.023 350.793 93.727 327 90.84 301H120v-30zm0 0"}}),_c('path',{attrs:{"d":"M285 271h-45v-45h-30v75h75zm0 0"}})]),_c('div',{class:[
              'spinner spinner--show-comparison',
              { active: _vm.comparisonLoading } ]},[_c('div',{staticClass:"spinner__inner"})]),_c('span',{staticClass:"section-content__icon-text"},[_vm._v(_vm._s(_vm.prevSeason)+" Comparison")])]):(
            !_vm.qualifyingPrevSeasonUnfetched && _vm.qualifyingResultsPrevSeason
          )?[_c('button',{class:[
              'section-content__icon section-content__icon--help',
              { active: _vm.deltaInfoActive } ],on:{"click":_vm.deltaInfoOverlay}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 426.667 426.667","svg-inline":'',"aria-label":'Previous event lap time comparison information icon',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('rect',{attrs:{"x":"202","y":"301","width":"30","height":"35","ry":"0","transform":"translate(-2 -2)"}}),_c('path',{attrs:{"d":"M213.333 0C95.513 0 0 95.513 0 213.333s95.513 213.333 213.333 213.333 213.333-95.513 213.333-213.333S331.154 0 213.333 0zm0 396.053c-96.495 0-182.72-86.225-182.72-182.72s86.225-182.72 182.72-182.72c96.446.117 182.602 86.273 182.72 182.72 0 96.496-86.224 182.72-182.72 182.72z"}}),_c('path',{attrs:{"d":"M291 154c-10.974-45.833-44.372-71.704-90.205-60.73-38.533 9.226-65.646 35.762-65.462 75.384H166c2.003-23.564 28.729-47.043 52.293-45.04s43.043 28.729 41.04 52.293c-4.358 21.204-25.38 40.169-47.013 39.413-10.486 0-12.987 14.987-12.987 14.987v45.013H230V245c45.12-11.635 71.912-45.699 61-91z"}})]),_c('span',{staticClass:"section-content__icon-text"},[_vm._v("Help "),_c('div',{staticClass:"help-pop-up"},[_vm._v(" Comparisons don't factor in circuit layout changes. Faded colours indicate a driver team change or constructor name change (or both). ")])])])]:_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"section__content"},[_c('div',{staticClass:"section__table-wrapper"},[_c('div',{class:['section-table section-table--qualifying active']},[_c('QualifyingTable',{attrs:{"qualifyingResults":_vm.qualifyingResults}})],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }