import Vue from 'vue'
import Router from 'vue-router'
import LatestRace from '@/views/LatestRace'
import OtherRaces from '@/views/OtherRaces'

Vue.config.productionTip = false
Vue.use(Router)

export default new Router({
  // mode: process.env.BUILD_FOR_APP ? 'hash' : 'history',
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'latestRace',
      component: LatestRace
    },
    {
      path: '/:season/:round/',
      // path: '/:season/:round/',
      name: 'otherRaces',
      component: OtherRaces
    }
  ]
})
