<template>
  <table class="section-table__table">
    <tbody>
      <tr>
        <th>P</th>
        <th colspan="2">Name</th>
        <th class="hide-xs">No</th>
        <th>Team</th>
        <th>Time</th>
        <th class="right-align">Pts</th>
      </tr>
      <tr v-for="(result, index) in raceResults" :key="result.index">
        <td>{{ result.positionText }}</td>
        <td class="section-table__flag">
          <img :src="getFlagImgUrl(index)" alt />
          <span class="section-table__nationality">{{
            result.Driver.nationality
          }}</span>
        </td>
        <td class="section-table__name">
          <a
            target="_blank"
            rel="noopener noreferrer"
            :data-driver="`${result.Driver.givenName} ${result.Driver.familyName}`"
            :href="`${result.Driver.url}`"
          >
            <span class="section-table__given-name">{{
              result.Driver.givenName
            }}</span>
            {{ result.Driver.familyName }}
          </a>
        </td>
        <td class="hide-xs section-table__no">{{ result.number }}</td>
        <td class="section-table__constructor">
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              :data-constructor="result.Constructor.name"
              :href="`${result.Constructor.url}`"
              >{{ result.Constructor.name }}</a
            >
          </span>
        </td>
        <td v-if="result.Time">{{ result.Time.time }}</td>
        <td v-else>{{ result.status }}</td>
        <td class="right-align" v-if="result.points">{{ result.points }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'default-table',
  props: {
    raceResults: [Array, Boolean]
  },
  methods: {
    getFlagImgUrl (i) {
      const fallback = require.context('@/assets/img/', false, /\.png$/)
      const flagImages = require.context('@/assets/img/flags/', false, /\.svg$/)
      let flagImage = ''
      try {
        flagImage = flagImages(`./${this.raceResults[i].Driver.nationality}.svg`)
      } catch (error) {
        flagImage = fallback('./fallback.png')
      }
      return flagImage
    }
  }
}
</script>
