import Vue from 'vue'
import App from './App'
import router from './router'

import './assets/scss/style.scss'
import 'leaflet/dist/leaflet.css'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.prototype.$navigatorOnline = navigator.onLine
Vue.prototype.$touchDevice = 'ontouchstart' in document.documentElement

/* eslint-disable no-new */

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
