<template>
  <table
    v-if="qualifyingResults.length"
    class="section-table__table"
    ref="defaultTable"
  >
    <tbody>
      <tr>
        <th>P</th>
        <th colspan="2">Name</th>
        <th
          :class="[
            'section-table__constructor',
            {
              'hide-xs':
                mutableQualifyingResults[0].Q2 ||
                mutableQualifyingResults[0].Q3,
            },
          ]"
        >
          Team
        </th>
        <th v-if="qualifyingResults[0].Q1" class="right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--asc': currentSortDir === 'asc' && currentSort === 'Q1',
                'active--desc':
                  currentSortDir === 'desc' && currentSort === 'Q1',
              },
            ]"
            @click="sortResults('Q1')"
          >
            Q1
          </button>
        </th>
        <td v-else class="right-align">&nbsp;</td>
        <th v-if="qualifyingResults[0].Q2" class="right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--asc': currentSortDir === 'asc' && currentSort === 'Q2',
                'active--desc':
                  currentSortDir === 'desc' && currentSort === 'Q2',
              },
            ]"
            @click="sortResults('Q2')"
          >
            Q2
          </button>
        </th>
        <td v-else class="right-align top-align">&nbsp;</td>
        <th v-if="qualifyingResults[0].Q3" class="right-align top-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--asc': currentSortDir === 'asc' && currentSort === 'Q3',
                'active--desc':
                  currentSortDir === 'desc' && currentSort === 'Q3',
              },
            ]"
            @click="sortResults('Q3')"
          >
            Q3
          </button>
        </th>
        <td v-else class="right-align top-align">&nbsp;</td>
      </tr>
      <tr
        v-for="(result, index) in mutableQualifyingResults"
        :key="result.index"
      >
        <td>{{ result.position }}</td>
        <td class="section-table__flag">
          <img :src="getFlagImgUrl(index)" alt />
          <span class="section-table__nationality">{{
            result.Driver.nationality
          }}</span>
        </td>
        <td class="section-table__name">
          <span
            :data-constructor="result.Constructor.name"
            :data-driver="`${result.Driver.givenName} ${result.Driver.familyName}`"
          >
            <span class="section-table__given-name">{{
              result.Driver.givenName
            }}</span>
            {{ result.Driver.familyName }}
          </span>
        </td>
        <td
          :class="[
            'section-table__constructor',
            {
              'hide-xs':
                mutableQualifyingResults[0].Q2 ||
                mutableQualifyingResults[0].Q3,
            },
          ]"
        >
          <span :data-constructor="result.Constructor.name">{{
            result.Constructor.name
          }}</span>
        </td>
        <td class="right-align top-align" v-if="result.Q1">
          <span class="section-table__quali-time">{{ result.Q1 }}</span>
          <span
            class="section-table__quali-time section-table__quali-compare"
            v-if="qualifyingResultsPrevSeason"
            v-html="
              qualiResultPrev(
                'Q1',
                result.Driver.driverId,
                result.Q1,
                result.Constructor.constructorId
              )
            "
          ></span>
        </td>
        <td v-else class="right-align top-align">&nbsp;</td>
        <td class="right-align top-align" v-if="result.Q2">
          <span class="section-table__quali-time">{{ result.Q2 }}</span>
          <span
            class="section-table__quali-time section-table__quali-compare"
            v-if="qualifyingResultsPrevSeason"
            v-html="
              qualiResultPrev(
                'Q2',
                result.Driver.driverId,
                result.Q2,
                result.Constructor.constructorId
              )
            "
          ></span>
        </td>
        <td v-else class="right-align top-align">&nbsp;</td>
        <td class="right-align top-align" v-if="result.Q3">
          <span class="section-table__quali-time">{{ result.Q3 }}</span>
          <span
            class="section-table__quali-time section-table__quali-compare"
            v-if="qualifyingResultsPrevSeason"
            v-html="
              qualiResultPrev(
                'Q3',
                result.Driver.driverId,
                result.Q3,
                result.Constructor.constructorId
              )
            "
          ></span>
        </td>
        <td v-else class="right-align top-align">&nbsp;</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'qualifying-table',
  props: {
    qualifyingResults: [Array, Boolean]
  },
  data () {
    return {
      currentSort: 'Q3',
      currentSortDir: 'asc',
      qualifyingResultsPrevSeason: false,
      mutableQualifyingResults: this.qualifyingResults
    }
  },
  mounted () {
    EventBus.$on('qualifyingResultsPrevSeason', (payload) => {
      this.qualifyingResultsPrevSeason = payload
    })
  },
  methods: {
    sortResults: function (e) {
      if (e === this.currentSort) return

      this.currentSort = e

      this.mutableQualifyingResults.sort((a, b) => {
        // Ignore if property is empty
        if (!a[e] || !b[e]) return false
        const modifier = 1
        if (a[e] < b[e]) return -1 * modifier
        if (a[e] > b[e]) return 1 * modifier
        else return false
      })
    },
    getFlagImgUrl (i) {
      const fallback = require.context('@/assets/img/', false, /\.png$/)
      const flagImages = require.context('@/assets/img/flags/', false, /\.svg$/)
      let flagImage = ''
      try {
        flagImage = flagImages(`./${this.mutableQualifyingResults[i].Driver.nationality}.svg`)
      } catch (error) {
        flagImage = fallback('./fallback.png')
      }
      return flagImage
    },
    qualiResultPrev (qualiNo, driverId, qualiTime, currentConstructor) {
      // Get index of driver
      const prevQualiTimeIndex = this.qualifyingResultsPrevSeason.findIndex(elem => {
        return elem.Driver.driverId === driverId
      })

      if (prevQualiTimeIndex >= 0) {
        let prevTime
        const prevConstructor = this.qualifyingResultsPrevSeason[prevQualiTimeIndex].Constructor.constructorId
        if (qualiNo === 'Q1') {
          prevTime = this.qualifyingResultsPrevSeason[prevQualiTimeIndex].Q1
        } else if (qualiNo === 'Q2') {
          prevTime = this.qualifyingResultsPrevSeason[prevQualiTimeIndex].Q2
        } else if (qualiNo === 'Q3') {
          prevTime = this.qualifyingResultsPrevSeason[prevQualiTimeIndex].Q3
        }

        if (prevTime) {
          // Split mins, secs, and ms in to array
          const a = prevTime.split(/[:.]/)
          const b = qualiTime.split(/[:.]/)

          // Convert array to ms
          const aa = (+a[0]) * 60 * 1000 + (+a[1]) * 1000 + (+a[2])
          const bb = (+b[0]) * 60 * 1000 + (+b[1]) * 1000 + (+b[2])

          // Subtract previous time from current time to get delta
          let delta = aa - bb

          if (delta < 0) {
            // Do stuff if negative integer
            this.deltaPositive = false
            this.deltaClassName = 'section-table__quali-pos'
          } else {
            // Do stuff if positive integer
            this.deltaPositive = true
            this.deltaClassName = 'section-table__quali-neg'
          }

          // Function to convert ms in to an appropiate time format
          function deltaFormatted (x) {
            let milliseconds = parseInt((x % 1000))
            let seconds = parseInt((x / 1000) % 60)
            let minutes = parseInt((x / (1000 * 60)) % 60)

            minutes = (minutes < 10) ? '0' + minutes : minutes

            // Remove first 0
            minutes = parseInt(minutes, 10)

            // Always display to 3 decimal place
            milliseconds = `00${milliseconds}`.slice(-3)

            if (minutes > 0) {
              // This should only run if a lap time delta is more than 1 minute (extremely unlikely)
              // Pad `seconds` with a 0 if less than 10
              seconds = (seconds < 10) ? '0' + seconds : seconds
              return minutes + ':' + seconds + '.' + milliseconds
            } else {
              return seconds + '.' + milliseconds
            }
          }
          if (delta < 0) {
            // If negative integer then convert to positive so it removes the '-' character
            delta = delta *= -1
          }

          if (this.deltaPositive) {
            delta = `-${deltaFormatted(delta)}`
          } else {
            delta = `+${deltaFormatted(delta)}`
          }

          if (prevConstructor === currentConstructor) {
            return `<span class="section-table__quali-prev">${prevTime}</span><span class="${this.deltaClassName}">${delta}<span>`
          } else {
            // If previous constructor is different (driver changed teams/constructor name change)
            return `<span class="section-table__quali-prev section-table__quali-prev--invalid">${prevTime}</span><span class="${this.deltaClassName}">${delta}<span>`
          }
        }
      } else {
        return ''
      }
    }
  },
  watch: {
    qualifyingResults: function () {
      this.mutableQualifyingResults = this.qualifyingResults
    }
  }
}
</script>
