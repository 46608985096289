<template>
  <footer class="site-footer">
    <a href="https://ko-fi.com/C0C37T4DO" target="_blank"
      ><img
        height="36"
        style="border: 0px; height: 36px"
        src="https://cdn.ko-fi.com/cdn/kofi3.png?v=3"
        border="0"
        alt="Buy Me a Coffee at ko-fi.com"
    /></a>
    <p class="site-footer__cite">
      Contact
      <a href="mailto:contact@f1stats.racing">F1 Stats</a>
    </p>
  </footer>
</template>
<script>

export default {
  name: 'site-footer'
}
</script>
