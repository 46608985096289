var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"section-table__table"},[_c('tbody',[_c('tr',[_c('th',[_c('button',{class:[
          'section-table__sortable section-table__sortable--chevron-right section-table__grid',
          {
            'active--desc':
              _vm.currentSortDir === 'asc' && _vm.currentSort === 'driverId',
            'active--asc':
              _vm.currentSortDir === 'desc' && _vm.currentSort === 'driverId',
          } ],on:{"click":_vm.sortDriver}},[_vm._v(" Name ")])]),_c('th',{staticClass:"right-align"},[_c('button',{class:[
            'section-table__sortable ',
            {
              'active--desc':
                _vm.currentSortDir === 'asc' && _vm.currentSort === 'lap',
              'active--asc':
                _vm.currentSortDir === 'desc' && _vm.currentSort === 'lap',
            } ],on:{"click":_vm.sortLap}},[_vm._v(" Lap ")])]),_c('th',{staticClass:"right-align"},[_c('button',{class:[
            'section-table__sortable',
            {
              'active--desc':
                _vm.currentSortDir === 'asc' && _vm.currentSort === 'time',
              'active--asc':
                _vm.currentSortDir === 'desc' && _vm.currentSort === 'time',
            } ],on:{"click":_vm.sortTime}},[_vm._v(" Time ")])]),_c('th',{staticClass:"right-align"},[_c('button',{class:[
            'section-table__sortable',
            {
              'active--desc':
                _vm.currentSortDir === 'asc' && _vm.currentSort === 'speed',
              'active--asc':
                _vm.currentSortDir === 'desc' && _vm.currentSort === 'speed',
            } ],on:{"click":_vm.sortSpeed}},[_vm._v(" Top Speed ")])])]),_vm._l((_vm.lapData),function(result){return _c('tr',{key:result.index},[_c('td',{staticClass:"section-table__name"},[_c('span',{attrs:{"data-constructor":result.Constructor.name,"data-driver":((result.Driver.givenName) + " " + (result.Driver.familyName))}},[_c('span',{staticClass:"section-table__given-name"},[_vm._v(_vm._s(result.Driver.givenName))]),_vm._v(" "+_vm._s(result.Driver.familyName)+" ")])]),(result.FastestLap)?[_c('td',{staticClass:"right-align"},[_vm._v(_vm._s(result.FastestLap.lap || ""))]),_c('td',{staticClass:"right-align"},[_vm._v(_vm._s(result.FastestLap.Time.time || ""))]),_c('td',{staticClass:"right-align"},[_vm._v(" "+_vm._s(result.FastestLap.AverageSpeed.speed + result.FastestLap.AverageSpeed.units || "")+" ")])]:[_c('td'),_c('td'),_c('td')]],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }