<template>
  <div class="race-wrapper latest-race">
    <!-- If qualifying and race data available -->
    <!-- Display Header, Race info, and Qualifying data -->
    <template
      v-if="
        qualifying.round === race.round && qualifying.season === race.season
      "
    >
      <RaceHeader
        :seasons="seasons"
        :race="race"
        :raceDataIsFetching="raceDataIsFetching"
      ></RaceHeader>
      <template>
        <RaceInformation
          v-if="!raceDataIsFetching"
          :raceDataIsFetching="raceDataIsFetching"
          :race="race"
        ></RaceInformation>
      </template>
      <RaceResults
        :raceResults="raceResults"
        :racePitStops="racePitStops"
      ></RaceResults>
    </template>
    <!-- If qualifying data available but no race (Not usually the case) -->
    <!-- Display Header, Race info, Race data, and Qualifying data -->
    <template v-else>
      <RaceHeader
        :seasons="seasons"
        :race="qualifying"
        :raceDataIsFetching="qualifyingDataIsFetching"
      ></RaceHeader>
      <RaceInformation
        v-if="!qualifyingDataIsFetching"
        :raceDataIsFetching="qualifyingDataIsFetching"
        :race="qualifying"
      ></RaceInformation>
    </template>
    <template
      v-if="qualifyingResults && race && race.Circuit && race.Circuit.Location"
    >
      <QualifyingResults
        v-if="!qualifyingDataIsFetching && !raceDataIsFetching"
        :seasons="seasons"
        :qualifyingResults="qualifyingResults"
        :qualifying="qualifying"
      ></QualifyingResults>
    </template>
    <ShowSeasons
      v-if="!qualifyingDataIsFetching && !raceDataIsFetching"
    ></ShowSeasons>
    <DriverStandings></DriverStandings>
    <ConstructorStandings></ConstructorStandings>
    <SiteFooter
      v-if="!qualifyingDataIsFetching && !raceDataIsFetching"
    ></SiteFooter>
  </div>
</template>

<script>
import RaceHeader from '@/components/RaceHeader'
import RaceInformation from '@/components/RaceInformation'
import RaceResults from '@/components/RaceResults'
import QualifyingResults from '@/components/QualifyingResults'
import SiteFooter from '@/components/SiteFooter'
import DriverStandings from '@/components/DriverStandings'
import ConstructorStandings from '@/components/ConstructorStandings'
import ShowSeasons from '@/components/elements/ShowSeasons'
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'latest-race',
  props: {
    qualifyingResults: [Array, Boolean],
    racePitStops: [Array, Boolean],
    raceResults: [Array, Boolean],
    race: [Object, Boolean],
    qualifying: [Object, Boolean],
    raceDataIsFetching: Boolean,
    qualifyingDataIsFetching: Boolean,
    seasons: Array
  },
  components: {
    RaceHeader,
    RaceInformation,
    RaceResults,
    QualifyingResults,
    ShowSeasons,
    DriverStandings,
    ConstructorStandings,
    SiteFooter
  },
  created () {
    // Used later to abort web request if it fails
    const controller = new AbortController()
    this.options = {
      cache: 'default',
      method: 'GET',
      signal: controller.signal
    }
    // Ergast data
    this.data = {
      results: 'https://ergast.com/api/f1/current/last/results.json',
      qualifying: 'https://ergast.com/api/f1/current/last/qualifying.json',
      // qualifying: 'https://ergast.com/api/f1/2008/5/qualifying.json',
      pitStops: 'https://ergast.com/api/f1/current/last/pitstops.json'
    }

    // Emitted to App.vue
    EventBus.$emit('raceRequest', this.data, this.options, controller)
  }
}
</script>
