<template>
  <table class="section-table__table">
    <tbody>
      <tr>
        <th>
          Name
        </th>
        <th class="section-table__lap-no right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'lap',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'lap',
              },
            ]"
            @click="sortPitStopsAscDes('lap')"
          >
            Lap
          </button>
        </th>
        <th class="section-table__stop right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'stop',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'stop',
              },
            ]"
            @click="sortPitStopsAscDes('stop')"
          >
            No
          </button>
        </th>
        <th class="section-table__time right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'time',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'time',
              },
            ]"
            @click="sortPitStopsAscDes('time')"
          >
            Time
          </button>
        </th>
        <th class="section-table__duration right-align">
          <button
            :class="[
              'section-table__sortable',
              {
                'active--desc':
                  currentSortDir === 'asc' && currentSort === 'duration',
                'active--asc':
                  currentSortDir === 'desc' && currentSort === 'duration',
              },
            ]"
            @click="sortPitStopsAscDes('duration')"
          >
            Duration
          </button>
        </th>
      </tr>
      <tr
        v-for="(result, index) in pitData"
        :key="result.index"
      >
        <template v-if="result.lap">
          <td class="section-table__name">
            <span
              :data-constructor="
                raceResults[findDriver(index)].Constructor.name
              "
              :data-driver="`${
                raceResults[findDriver(index)].Driver.givenName
              } ${raceResults[findDriver(index)].Driver.familyName}`"
            >
              <span class="section-table__given-name">{{
                raceResults[findDriver(index)].Driver.givenName
              }}</span>
              {{ raceResults[findDriver(index)].Driver.familyName }}
            </span>
          </td>
          <td class="section-table__lap-no right-align">{{ result.lap }}</td>
          <td class="section-table__stop right-align">{{ result.stop }}</td>
          <td class="section-table__time right-align">{{ result.time }}</td>
          <td class="section-table__duration right-align">
            {{ result.duration }}
          </td>
        </template>
        <template v-else>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'pit-table',
  props: {
    racePitStops: [Array, Boolean],
    raceResults: [Array, Boolean],
    currentSortDir: [String, Boolean],
    currentSort: [String]
  },
  data () {
    return {
      pitData: []
    }
  },
  mounted () {
    // Clone original data, but keep reactivity indepedant to new array
    this.pitData = this.racePitStops.map(o => ({
      ...o
    }))
    EventBus.$once('sortResultsAscDesInit', 'duration', true, this.racePitStops)
  },
  filters: {
    cleanName (value) {
      let val = value.substr(value.indexOf('_') + 1)
      val = val.charAt(0).toUpperCase() + val.substr(1)
      return val
    }
  },
  methods: {
    sortPitStopsAscDes (e) {
      EventBus.$emit('sortPitStopsAscDes', e, false, this.pitData)
    },
    sortDriver () {
      EventBus.$emit('sortResultsAscDes', 'driverId', false, this.pitData)
    },
    findDriver (index) {
      const driverId = this.racePitStops[index].driverId
      const detailed = this.raceResults.findIndex(elem => {
        return elem.Driver.driverId === driverId
      })
      return detailed
    }
  }
}
</script>
