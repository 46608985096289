<template>
  <transition
    appear
    name="fade-in"
  >
    <section
      v-if="raceResults[0]"
      class="main-results race-results section section--color2"
    >
      <header class="section-content__header">
        <transition name="fade">
          <h2 class="heading">{{ this.resultsTableHeading }}</h2>
        </transition>
        <div class="section-content__icons">
          <!-- Pits -->
          <template v-if="racePitStops">
            <button
              :class="['section-content__icon', { active: pitDataActive }]"
              v-on:click="activatePitData()"
            >
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M256 512c140.957 0 256-115.047 256-256C512 115.043 396.95 0 256 0 115.04 0 0 115.05 0 256c0 140.96 115.05 256 256 256zm0-482c124.617 0 226 101.383 226 226S380.617 482 256 482 30 380.617 30 256 131.383 30 256 30zm0 0"/><path d="M256 301c24.813 0 45-20.188 45-45s-20.188-45-45-45-45 20.188-45 45 20.188 45 45 45zm0-60c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0"/><path d="M256 421c92.043 0 165-74.66 165-165 0-10.176-1.781-27.273-1.957-28-9.555-39.688-20.238-63.668-53.043-95.117-13.215-12.668-42.195-29.051-56-33.051C291.71 94.535 278.105 91 256 91c-90.953 0-165 73.563-165 165 0 91.18 73.988 165 165 165zm-19.992-31.469L250.27 361h11.46l14.266 28.531a137.186 137.186 0 01-39.988 0zm125.633-49.457l-17.57-26.613 5.726-9.922 31.867 1.91a134.425 134.425 0 01-20.023 34.625zm20.023-133.523l-31.867 1.91-5.727-9.922 17.57-26.613a134.425 134.425 0 0120.024 34.625zm-105.672-84.082L261.73 151h-11.46l-14.266-28.531a137.186 137.186 0 0139.988 0zM150.36 171.926l17.575 26.613-5.73 9.922-31.868-1.91a134.425 134.425 0 0120.023-34.625zM121 256c0-6.668.492-13.316 1.457-19.871l56.453 3.39 24.274-42.039-31.153-47.18a135.052 135.052 0 0134.403-19.894L231.73 181h48.54l25.3-50.602a135.339 135.339 0 0134.395 19.907l-31.149 47.175 24.27 42.04 56.457-3.391a135.953 135.953 0 01-.004 39.742l-56.45-3.39-24.273 42.039 31.149 47.175a134.526 134.526 0 01-34.395 19.903L280.27 331h-48.54l-25.3 50.598a135.019 135.019 0 01-34.399-19.899l31.153-47.18-24.27-42.039-56.457 3.391A136.526 136.526 0 01121 256zm41.203 47.54l5.73 9.92-17.574 26.614a134.425 134.425 0 01-20.023-34.625zm0 0"/></svg>
              <span class="section-content__icon-text"><span class="hide-gt-sm">Pits</span>
                <span class="show-gt-sm">Pit Stops</span></span>
            </button>
          </template>
          <!-- Laps -->
          <template v-if="this.raceResults[0].FastestLap">
            <button
              :class="['section-content__icon', { active: lapDataActive }]"
              v-on:click="activateLapData()"
            >
              <svg viewBox="0 -31 512 512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M256 0C114.84 0 0 114.395 0 255c0 72.559 31.828 142.266 87.324 191.246L91.578 450h328.844l4.254-3.754C480.172 397.266 512 327.56 512 255 512 114.395 397.16 0 256 0zm152.977 420H103.023C56.547 376.95 30 317.129 30 255 30 130.934 131.383 30 256 30s226 100.934 226 225c0 62.129-26.547 121.95-73.023 165zm0 0"/><path d="M256 60C148.477 60 61 147.477 61 255v15h90v-15c0-50.898 36.402-93.445 84.54-102.992l-31 108.508C190.062 274.199 181 293.555 181 315c0 41.355 33.645 75 75 75s75-33.645 75-75c0-21.445-9.063-40.8-23.54-54.48l-31-108.512C324.599 161.555 361 204.102 361 255v15h90v-15c0-107.523-87.477-195-195-195zm78.648 49.984l-16.722 25.086C299.367 125.445 278.309 120 256 120s-43.367 5.445-61.926 15.07l-16.722-25.086C200.746 97.246 227.539 90 256 90s55.254 7.246 78.648 19.984zM121.828 240H91.676c4.133-45.668 26.957-86.02 60.738-113.34l16.723 25.086c-25.813 21.746-43.39 52.969-47.309 88.254zM256 360c-24.813 0-45-20.188-45-45s20.188-45 45-45 45 20.188 45 45-20.188 45-45 45zm0-120a75.02 75.02 0 00-14.82 1.477L256 189.602l14.82 51.875A75.02 75.02 0 00256 240zm134.172 0c-3.918-35.285-21.5-66.508-47.309-88.254l16.723-25.086c33.781 27.32 56.605 67.672 60.738 113.34zm0 0"/><path d="M241 300h30v30h-30zm0 0M91 330h60v30H91zm0 0M361 330h60v30h-60zm0 0"/></svg>
              <span class="section-content__icon-text"><span class="show-gt-sm">Fastest</span> Laps</span>
            </button>
          </template>
          <!-- Pos -->
          <button
            :class="['section-content__icon', { active: positionDataActive }]"
            v-on:click="activatePositionData()"
          >
            <svg viewBox="0 -16 512 512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M91 240h85.79l-60 240h278.42l-60-240H421v240h91V0h-91v30H91V0H0v480h91zm240-90h-60v-30h60zm30-30h60v30h-60zm-90 90v-30h60v30zm-90 0v-30h60v30zm90-120V60h60v30zm-30 0h-60V60h60zm0 30v30h-60v-30zm-90 30H91v-30h60zm0 30v30H91v-30zm205.79 270H271v-60h-30v60h-85.79l52.5-210H241v30h30v-30h33.29zM361 210v-30h60v30zm90-180h31v420h-31zm-30 60h-60V60h60zM151 60v30H91V60zM61 450H30V30h31zm0 0"/><path d="M241 300h30v60h-30zm0 0"/></svg>
            <span class="section-content__icon-text">Pos<span class="show-gt-sm">ition</span></span>
          </button>
          <!-- Default -->
          <button
            :class="['section-content__icon', { active: defaultDataActive }]"
            v-on:click="activateDefaultData()"
          >
            <svg viewBox="1 -6 511.999 511" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M360.7 258.656H151.296v59.828H.73V498.97h510.54V378.313H360.7zM30.644 469.055V348.398h120.652v120.657zm300.14 0h-149.57V288.57h149.57zm150.57-60.828v60.828H360.7v-60.828zm0 0M284.379 58.004L256 .5l-28.379 57.504-63.46 9.223 45.917 44.757-10.84 63.204L256 145.347l56.758 29.84-10.84-63.204 45.922-44.757zm-11.348 62.5L256 111.55l-17.031 8.953 3.254-18.961-13.778-13.43 19.04-2.77L256 68.094l8.516 17.25 19.039 2.77-13.778 13.43zm0 0"/><path d="M36.805 235.02l56.758-29.844 56.761 29.844-10.84-63.204 45.918-44.761-63.457-9.223-28.379-57.504L65.2 117.805 0 127.055l47.617 44.914zm48.23-89.817l8.531-17.281 8.512 17.254 19.04 2.765-13.778 13.43 3.254 18.961-17.028-8.95-17.03 8.954 3.276-19.121-14.074-13.274zm0 0M418.434 60.328l-28.38 57.504-63.456 9.223 45.918 44.757-10.84 63.204 56.758-29.84 56.761 29.84-10.812-63.047L512 127.055l-65.2-9.25zm17.03 120.004l-17.03-8.953-17.028 8.953 3.254-18.96-13.777-13.43 19.039-2.766 8.512-17.254 8.53 17.281 19.298 2.738-14.075 13.274zm0 0M300.871 408.227h-29.914v-89.743h-59.828v29.914h29.914v59.829h-29.914v29.914h89.742zm0 0"/></svg>
            <span class="section-content__icon-text">Results</span>
          </button>
        </div>
      </header>
      <div class="section__content">
        <div class="section__table-wrapper">
          <transition-group
            name="table"
            tag="div"
          >
            <!-- Default -->
            <div
              v-if="defaultDataActive"
              :class="[
                'section-table section-table--default',
                { active: defaultDataActive },
              ]"
              key="tableItem1"
            >
              <DefaultTable
                :raceResults="raceResults"
                :currentSortDir="currentSortDir"
                :currentSort="currentSort"
                ref="defaultTable"
              ></DefaultTable>
            </div>
            <!-- Laps -->
            <div
              v-if="raceResults[0].FastestLap && lapDataActive"
              :class="[
                'section-table section-table--lap',
                { active: lapDataActive },
              ]"
              key="tableItem2"
            >
              <LapTable
                :raceResults="raceResults"
                :currentSortDir="currentSortDir"
                :currentSort="currentSort"
                ref="lapTable"
              ></LapTable>
            </div>
            <!-- Position -->
            <div
              v-if="positionDataActive"
              :class="[
                'section-table section-table--position',
                { active: positionDataActive },
              ]"
              key="tableItem3"
            >
              <PositionTable
                :raceResults="raceResults"
                :currentSortDir="currentSortDir"
                :currentSort="currentSort"
                ref="positionTable"
              ></PositionTable>
            </div>
            <!-- Pits -->
            <div
              v-if="racePitStops && pitDataActive"
              :class="[
                'section-table section-table--pit',
                { active: pitDataActive },
              ]"
              key="tableItem4"
            >
              <PitTable
                :raceResults="raceResults"
                :racePitStops="racePitStops"
                :currentSortDir="currentSortDir"
                :currentSort="currentSort"
                ref="pitTable"
              ></PitTable>
            </div>
          </transition-group>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'
import DefaultTable from '@/components/tables/DefaultTable'
import PitTable from '@/components/tables/PitTable'
import PositionTable from '@/components/tables/PositionTable'
import LapTable from '@/components/tables/LapTable'

export default {
  name: 'race-results',
  components: {
    DefaultTable,
    PitTable,
    LapTable,
    PositionTable
  },
  props: {
    racePitStops: [Array, Boolean],
    raceResults: [Array, Boolean]
  },
  data () {
    return {
      defaultDataActive: true,
      lapDataActive: false,
      pitDataActive: false,
      positionDataActive: false,
      resultsTableHeading: 'Race Results',
      currentSort: 'position',
      currentSortDir: 'asc'
    }
  },
  mounted () {
    // this.racePitStops = this.racePitStops
    Object.assign(this.racePitStops, this.racePitStops)
    EventBus.$on('sortResultsAscDesInit', (type, bool, data) => {
      // True or false for asc desc sorting
      this.sortResultsAscDes(type, bool, data)
    })

    EventBus.$on('sortResultsAscDes', (type, bool, data) => {
      // True or false for asc desc sorting
      this.sortResultsAscDes(type, bool, data)
    })

    EventBus.$on('sortPitStopsAscDes', (type, bool, data) => {
      this.sortPitStopsAscDes(type, bool, data)
    })
  },
  methods: {
    activateDefaultData () {
      this.defaultAll()
      this.defaultDataActive = true
      this.resultsTableHeading = 'Race Results'
    },
    activateLapData () {
      this.defaultAll()
      this.lapDataActive = true
      this.resultsTableHeading = 'Fastest Laps'
      this.currentSort = 'time'
    },
    activatePitData () {
      this.defaultAll()
      this.pitDataActive = true
      this.resultsTableHeading = 'Pit Stops'
      this.currentSort = 'time'
    },
    activatePositionData () {
      this.defaultAll()
      this.positionDataActive = true
      this.resultsTableHeading = 'Driver Position'
      this.currentSort = 'position'
    },
    defaultAll () {
      this.currentSortDir = 'asc'
      this.lapDataActive = false
      this.positionDataActive = false
      this.pitDataActive = false
      this.defaultDataActive = false
    },
    sortResultsAscDes: function (s, t, data) {
      // s = 'asc' / 'desc'
      // t = true / false
      if (s === this.currentSort) {
        // if s == current sort, reverse
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      if (t) {
        // Always sort ascending if switching to a new table
        this.currentSortDir = this.currentSortDir === 'asc'
      }
      this.currentSort = s

      // add return false here for if not fastest lap
      this.sortResults(this.currentSort, data)
    },
    sortResults: function (e, data) {
      // e = 'asc' / 'desc'
      data.sort((a, b) => {
        // OPTIMISE THIS! dont run any of this until a sorting filter has been clicked. instead return the data to the original state!
        if (this.lapDataActive && 'FastestLap' in a) {
          let modifier = 1
          if (this.currentSortDir === 'desc') modifier = -1
          const lapParsed = parseInt(a.FastestLap[e])
          const parseVal = parseInt(a[e])

          if (e === 'driverId') {
            if (a.Driver.driverId < b.Driver.driverId) return -1 * modifier
            if (a.Driver.driverId > b.Driver.driverId) return 1 * modifier
          } else if (e === 'lap') {
            if (lapParsed < 10) {
              a.FastestLap[e] = 0 + lapParsed
            }
            if (a.FastestLap[e] < b.FastestLap[e]) return -1 * modifier
            if (a.FastestLap[e] > b.FastestLap[e]) return 1 * modifier
          } else if (e === 'time') {
            if (a.FastestLap.Time[e] < b.FastestLap.Time[e]) return -1 * modifier
            if (a.FastestLap.Time[e] > b.FastestLap.Time[e]) return 1 * modifier
          } else if (e === 'speed') {
            if (a.FastestLap.AverageSpeed[e] < b.FastestLap.AverageSpeed[e]) return -1 * modifier
            if (a.FastestLap.AverageSpeed[e] > b.FastestLap.AverageSpeed[e]) return 1 * modifier
          } else {
            if (parseVal < 10) {
              a[e] = 0 + parseVal
            }
            if (a[e] < b[e]) return -1 * modifier
            if (a[e] > b[e]) return 1 * modifier
          }
        } else {
          let modifier = 1
          if (this.currentSortDir === 'desc') modifier = -1
          const parseVal = parseInt(a[e])
          if (e === 'driverId') {
            if (a.Driver.driverId < b.Driver.driverId) return -1 * modifier
            if (a.Driver.driverId > b.Driver.driverId) return 1 * modifier
          } else if (parseVal < 10) {
            a[e] = 0 + parseVal
          }
          if (e === 'team') {
            if (a.Constructor.name < b.Constructor.name) return -1 * modifier
            if (a.Constructor.name > b.Constructor.name) return 1 * modifier
          } else {
            if (a[e] < b[e]) return -1 * modifier
            if (a[e] > b[e]) return 1 * modifier
          }
        }
        return false
      })
    },
    sortPitStopsAscDes: function (s, t, data) {
      // if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      if (t) {
        // Always sort ascending if switching to a new table
        this.currentSortDir = this.currentSortDir === 'asc'
      }
      this.currentSort = s
      this.sortPitStops(this.currentSort, data)
    },
    sortPitStops: function (e, data) {
      data.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') modifier = -1

        const parseVal = parseInt(a[e])

        if (parseVal < 10) {
          a[e] = 0 + parseVal
        }

        if (a[e] < b[e]) return -1 * modifier
        if (a[e] > b[e]) return 1 * modifier
        return false
      })
    }
  }
}
</script>

<style scoped>
.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.3s ease-in 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in 0.5s;
}

.section-table {
  display: flex;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
}

.table-enter,
.table-leave-to {
  opacity: 0;
  transform: translate3d(1rem, 0, 0);
}

.table-leave-active {
  position: absolute;
}
</style>
