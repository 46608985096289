<template>
  <transition appear name="fade-in">
    <section v-if="qualifyingResults" class="qualifying-results race-results section section--color3">
      <header class="section-content__header" v-on:mouseleave="deltaInfoOverlayHide">
        <h2 class="heading" ref="heading">{{ this.qualifyingTableHeading }}</h2>
        <transition appear name="comparison-fade-in" v-if="!qualifyingPrevSeasonUnfetched">
          <span class="mini-sub-heading">Comparison: {{ qualifying.season }} / {{ prevSeason }}</span>
        </transition>
        <div class="section-content__icons" v-if="$navigatorOnline">
          <button class="section-content__icon" v-on:click.once="fetchPrevQualifyingData()" v-if="this.prevSeason !== 2002 && qualifyingPrevSeasonUnfetched && qualifyingResultsPrevSeason">
            <svg viewBox="-31 0 512 512" xmlns="http://www.w3.org/2000/svg" :class="[
                'section-content__icon--previous-result',
                { inactive: comparisonLoading },
              ]" v-bind:svg-inline="''" v-bind:aria-label="'Previous event lap time comparison icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M225 512c124.066 0 225-101.383 225-226 0-60.824-24.078-119.578-70.36-163.43L405 97.215l19.395 19.39 21.21-21.21-60-60-21.21 21.21L383.785 76l-27.344 27.344C330.563 84.637 301.2 71.78 270 65.48V30h30V0H150v30h30v35.48C78.281 86.023 0 176.125 0 286c0 124.617 100.934 226 225 226zM210 30h30v31.504c-4.973-.324-9.973-.504-15-.504s-10.027.18-15 .504zm15 61c107.523 0 195 87.477 195 195 0 108.074-87.477 196-195 196S30 394.074 30 286c0-107.523 87.477-195 195-195zm0 0"/><path d="M225 451c90.98 0 165-74.02 165-165s-74.02-165-165-165S60 195.02 60 286s74.02 165 165 165zM90.84 271c2.887-26 13.183-49.793 28.73-69.215l20.578 20.574 21.211-21.21-20.574-20.579C160.207 165.023 184 154.727 210 151.84V181h30v-29.16c26 2.887 49.793 13.183 69.215 28.73l-20.574 20.578 21.21 21.211 20.579-20.574C345.977 221.207 356.273 245 359.16 271H330v29.988h29.16c-2.887 26-13.183 49.805-28.73 69.227l-20.578-20.574-21.211 21.21 20.574 20.579C289.793 406.977 266 417.273 240 420.16V391h-30v29.16c-26-2.887-49.793-13.183-69.215-28.73l20.574-20.578-21.21-21.211-20.579 20.574C104.023 350.793 93.727 327 90.84 301H120v-30zm0 0"/><path d="M285 271h-45v-45h-30v75h75zm0 0"/></svg>
            <div :class="[
                'spinner spinner--show-comparison',
                { active: comparisonLoading },
              ]">
              <div class="spinner__inner"></div>
            </div>
            <span class="section-content__icon-text">{{ prevSeason }} Comparison</span>
          </button>
          <template name="comparison-fade-in" appear v-else-if="
              !qualifyingPrevSeasonUnfetched && qualifyingResultsPrevSeason
            ">
            <button :class="[
                'section-content__icon section-content__icon--help',
                { active: deltaInfoActive },
              ]" v-on:click="deltaInfoOverlay">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667" v-bind:svg-inline="''" v-bind:aria-label="'Previous event lap time comparison information icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="202" y="301" width="30" height="35" ry="0" transform="translate(-2 -2)"/><path d="M213.333 0C95.513 0 0 95.513 0 213.333s95.513 213.333 213.333 213.333 213.333-95.513 213.333-213.333S331.154 0 213.333 0zm0 396.053c-96.495 0-182.72-86.225-182.72-182.72s86.225-182.72 182.72-182.72c96.446.117 182.602 86.273 182.72 182.72 0 96.496-86.224 182.72-182.72 182.72z"/><path d="M291 154c-10.974-45.833-44.372-71.704-90.205-60.73-38.533 9.226-65.646 35.762-65.462 75.384H166c2.003-23.564 28.729-47.043 52.293-45.04s43.043 28.729 41.04 52.293c-4.358 21.204-25.38 40.169-47.013 39.413-10.486 0-12.987 14.987-12.987 14.987v45.013H230V245c45.12-11.635 71.912-45.699 61-91z"/></svg>
              <span class="section-content__icon-text">Help
                <div class="help-pop-up">
                  Comparisons don't factor in circuit layout changes. Faded
                  colours indicate a driver team change or constructor name
                  change (or both).
                </div>
              </span>
            </button>
          </template>
        </div>
      </header>
      <div class="section__content">
        <div class="section__table-wrapper">
          <div :class="['section-table section-table--qualifying active']">
            <QualifyingTable :qualifyingResults="qualifyingResults"></QualifyingTable>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import QualifyingTable from '@/components/tables/QualifyingTable'
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'qualifying-results',
  components: {
    QualifyingTable
  },
  props: {
    qualifyingResults: [Array, Boolean],
    qualifying: [Object, Boolean],
    seasons: Array
  },
  data () {
    return {
      qualifyingTableHeading: 'Qualifying Times',
      qualifyingResultsPrevSeason: false,
      qualifyingPrevSeasonUnfetched: true,
      qualifyingPrevSeasonRetrieved: false,
      prevSeason: '',
      prevSeasonIndex: 0,
      prevRound: '',
      deltaInfoActive: false,
      prevRaceName: '',
      comparisonLoading: false
    }
  },
  created () {
    this.isPreviousQualiDataAvailable()

    // Reset
    this.qualifyingPrevSeasonUnfetched = true
  },
  methods: {
    deltaInfoOverlay () {
      this.deltaInfoActive = !this.deltaInfoActive
    },
    deltaInfoOverlayHide () {
      this.deltaInfoActive = false
    },
    isPreviousQualiDataAvailable () {
      if (!this.qualifyingPrevSeasonRetrieved) {
        const year = parseInt(this.qualifying.season)
        const prevYear = parseInt(this.prevSeason)

        if (year <= 2002 || (prevYear <= 2002 && prevYear > 0)) {
          // No data exists pre-2003
          this.qualifyingResultsPrevSeason = false
        } else {
          this.getPrevSeasonIndex(false)
          this.qualifyingResultsPrevSeason = true
        }
      }
    },
    getPrevSeasonIndex (seasonPrior) {
      if (!seasonPrior) {
        // Start checking for matching data at previous season
        this.prevSeason = this.qualifying.season - 1
      } else {
        this.prevSeason = seasonPrior
      }

      // get index of previous season
      const prevSeasonString = this.prevSeason.toString()
      this.prevSeasonIndex = this.seasons.findIndex(elem => {
        return elem.season === prevSeasonString
      })
      if (this.prevSeason >= 2003) {
        this.getPrevRace()
      } else {
        this.qualifyingResultsPrevSeason = false
      }
    },
    getPrevRace () {
      // Find matching circuit
      if (this.seasons[this.prevSeasonIndex]) {
        this.prevRace = this.seasons[this.prevSeasonIndex].Races.find(elem => {
          if (elem.circuitId === this.qualifying.Circuit.circuitId) {
            return elem
          } else {
            return false
          }
        })
      } else {
        return false
      }

      if (this.prevRace) {
        this.prevSeason = this.prevRace.season
        this.prevRound = this.prevRace.round
        this.prevRaceName = this.prevRace.raceName
      } else {
        // If no match then try the season prior to this
        const seasonPrior = this.prevSeason - 1
        this.getPrevSeasonIndex(seasonPrior)
      }
    },
    fetchPrevQualifyingData () {
      this.comparisonLoading = true
      let controller = new AbortController()
      this.request = `https://ergast.com/api/f1/${this.prevSeason}/${this.prevRound}/qualifying.json`
      this.options = {
        cache: 'force-cache',
        method: 'GET',
        signal: controller.signal
      }

      fetch(this.request, this.options)
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for constructor standings data failed: ', err))
        .then((response) => response.json()
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(`'${err}' happened!`)
            return {}
          }))
        .then((json) => {
          if (json.status === 504 && this.$route.name === 'otherRaces') {
            // Abort fetch request before it has completed then use cached data
            controller.abort()
            controller = new AbortController()
            return fetch(this.request, {
              cache: 'force-cache',
              mode: 'same-origin',
              signal: controller.signal
            })
          }
          // populate data
          if (json.MRData.RaceTable.Races.length >= 1) {
            this.qualifyingResultsPrevSeason = json.MRData.RaceTable.Races[0].QualifyingResults

            // Emitted to QualifyingTable.vue
            EventBus.$emit('qualifyingResultsPrevSeason', this.qualifyingResultsPrevSeason)
          } else {
            this.qualifyingResultsPrevSeason = false
          }

          this.qualifyingPrevSeasonUnfetched = false
          this.comparisonLoading = false
        })
    }
  },
  updated () {
    // Set to false again because the data will have changed at this point
    this.isPreviousQualiDataAvailable()
    this.qualifyingPrevSeasonRetrieved = true
  },
  watch: {
    qualifyingPrevSeasonUnfetched: function (newValue) {
      if (newValue === false) {
        this.$refs.heading.style.transform = 'translate3d(0, -1.25rem, 0)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
  transform: translate3d(0, 0.5rem, 0);
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.3s ease-in 0.5s,
    transform 0.9s cubic-bezier(0.23, 1, 0.32, 1) 1s;
}

.comparison-fade-in-enter,
.comparison-fade-in-leave-to {
  opacity: 0;
}

.comparison-fade-in-enter-active,
.comparison-fade-in-leave-active {
  transition: opacity 0.2s ease-in;
}

.heading {
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.section-content__icon--help {
  .help-pop-up {
    transition: opacity 0.2s ease-in;
    opacity: 0;
    pointer-events: none;
    right: 3.5rem;
    left: auto;
    width: 12.25rem;
    bottom: 0.125rem;
    z-index: 4;
    text-align: left;
    color: #fff;

    @media only screen and (min-width: 480px) {
      width: 15.25rem;
    }

    &::after,
    &::before {
      right: -9px;
      left: auto;
    }

    &::before {
      border-color: transparent transparent transparent #fff;
      border-width: 4px;
      top: auto;
      bottom: 0.333rem;
    }

    &::after {
      border-width: 3px;
      display: none;
      right: 1rem;
      left: auto;
      top: 100%;
    }
  }

  &.active {
    .help-pop-up {
      pointer-events: all;
      opacity: 1;
      color: #fff;
    }
  }
}

.section-content__icon.active svg {
  transform: none;
}
</style>
