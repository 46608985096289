<template>
  <div
    id="app"
    :class="[
      'app',
      { 'overflow-active': overflowActive, 'data-fetched': !dataIsFetching },
    ]"
    :style="{ height: raceSelectHeight }"
  >
    <div :class="['spinner', { active: qualifyingDataIsFetching }]">
      <div class="spinner__inner"></div>
    </div>
    <transition :name="appFadeIn">
      <router-view
        :key="$route.fullPath"
        :race="race"
        :seasons="seasons"
        :raceDataIsFetching="raceDataIsFetching"
        :qualifyingDataIsFetching="qualifyingDataIsFetching"
        :raceResults="raceResults"
        :racePitStops="racePitStops"
        :qualifying="qualifying"
        :qualifyingResults="qualifyingResults"
      ></router-view>
    </transition>
  </div>
</template>

<script>
import EventBus from '@/event-bus/event-bus.js'
import seasonsData from '@/assets/data/seasons.json'
import {
  isObjectEmpty
} from '@/mixins/isObjectEmpty.js'

export default {
  name: 'App',
  mixins: [isObjectEmpty],
  data () {
    return {
      seasons: seasonsData,
      race: {},
      qualifying: {},
      raceResults: [],
      racePitStops: [],
      raceCircuitLocation: {},
      qualifyingResults: [],
      error: false,
      dataIsFetching: true,
      raceDataIsFetching: true,
      qualifyingDataIsFetching: true,
      overflowActive: false,
      raceSelectHeight: '',
      appFadeIn: 'app-fade-in'
    }
  },
  created () {
    // Emitted from LatestRace.vue and OtherRaces.vue
    EventBus.$on('raceRequest', (data, options, controller) => {
      // True or false for asc desc sorting
      this.fetchData(data, options, controller)
    })

    // Emitted from RaceSelect.vue
    EventBus.$on('raceSelectOverlayProps', (val, isActive) => {
      this.raceSelectHeight = val
      if (isActive) {
        this.overflowActive = true
        return
      } !this.overflowActive ? this.overflowActive = true : this.overflowActive = false
      if (!this.overflowActive) this.raceSelectHeight = ''
    })

    // Emitted from RaceInformation.vue
    EventBus.$on('dataIsFetching', (bool) => {
      // Last fetch request complete (currently the wiki data)
      this.dataIsFetching = bool
    })

    // get latest season data and push to season json file
    let controllerLatestSeason = new AbortController()
    this.fetchOptions = {
      cache: 'force-cache',
      method: 'GET',
      signal: controllerLatestSeason.signal
    }
    // setTimeout(() => {
    // Stagger so we're not flooding the API with simultaneous requests
    fetch(`https://ergast.com/api/f1/${process.env.VUE_APP_CURRENT_SEASON}.json`, this.fetchOptions)
      // eslint-disable-next-line no-console
      .catch((err) => console.error('Fetch request for Latest season data failed: ', err))
      .then((response) => response.json()
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(`'${err}' happened!`)
          return {}
        }))
      .then((json) => {
        if (json.status === 504) {
          // Abort fetch request before it has completed then use cached data
          controllerLatestSeason.abort()
          controllerLatestSeason = new AbortController()
          return fetch(`https://ergast.com/api/f1/${process.env.VUE_APP_CURRENT_SEASON}.json`, {
            cache: 'force-cache',
            mode: 'same-origin',
            signal: controllerLatestSeason.signal
          })
        }
        // Push latest season data to existing season data
        this.seasons.push(json.MRData.RaceTable)
        // Reverse order of seasons (So it's year descending)
        this.seasons.reverse()
      })
    // }, 500)
  },
  methods: {
    IsJsonString (str) {
      // Check if valid JSON
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    fetchData (data, options, controller) {
      fetch(data.results, options)
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for Race Results data failed: ', err))
        .then((response) => response.json()
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(`'${err}' happened!`)
            return {}
          }))
        .then((json) => {
          if (json.status === 504 && this.IsJsonString(json)) {
            // Abort fetch request before it has completed then use cached data
            controller.abort()
            controller = new AbortController()
            return fetch(data.results, {
              cache: 'force-cache',
              mode: 'same-origin',
              signal: controller.signal
            })
          }

          // Clear any previously stored data
          this.race = false
          this.raceResults = false
          this.raceCircuitLocation = false

          if (json.MRData.RaceTable.Races.length > 0) {
            //  If not empty then set data
            this.race = json.MRData.RaceTable.Races[0]
            this.raceResults = this.race.Results

            this.raceDataIsFetching = false
            this.raceCircuitLocation = parseInt(this.race.Circuit.Location.lat)

            // Add grid new key pair values denoting grid start and finishing place difference
            Object.keys(this.raceResults).forEach((el) => {
              const gridDiff = (this.raceResults[el].grid - this.raceResults[el].position)
              this.$set(this.raceResults[el], 'gridDiff', gridDiff)
            })
            // Add grid new key pair values denoting grid start and finishing place difference
            const slug = this.race.raceName.toLowerCase().replace(/ /g, '_')
            this.$set(this.race, 'slug', slug)
          }
        })
        .then(json => fetchQualifying(json))

      const fetchQualifying = () => {
        // Qualifying times
        fetch(data.qualifying, options)
          // eslint-disable-next-line no-console
          .catch((err) => console.error('Fetch request for Qualifying data failed: ', err))
          .then((response) => response.json()
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error(`'${err}' happened!`)
              return {}
            }))
          .then((json) => {
            if (json.status === 504) {
              // Abort fetch request before it has completed then use cached data
              controller.abort()
              controller = new AbortController()
              return fetch(data.qualifying, {
                cache: 'force-cache',
                mode: 'same-origin',
                signal: controller.signal
              })
            }

            // Clear any previously stored data
            this.qualifying = false
            this.qualifyingResults = false

            // populate data
            if (json.MRData.RaceTable.Races.length >= 1) {
              this.qualifying = json.MRData.RaceTable.Races[0]
              this.qualifyingResults = this.qualifying.QualifyingResults
            }

            this.qualifyingDataIsFetching = false

            // Unless null
            if (this.emptyObject(this.qualifyingResults)) {
              this.qualifyingResults = false
            }
          })
          .then(() => {
            // This can't run any earlier, as we need to track ID to populate the wiki data
            EventBus.$emit('wikiDataPopulate', controller)
          })
          .then(json => fetchPitStops(json))
      }

      const fetchPitStops = () => {
        // Clear any previously stored data
        this.racePitStops = false
        // Pitstop data - Not visble on page load, so fetching this later on
        fetch(data.pitStops, options)
          .then((response) => response.json()
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error(`'${err}' happened!`)
              return {}
            }))
          .then((json) => {
            if (json.status === 504) {
              // Abort fetch request before it has completed then use cached data
              controller.abort()
              controller = new AbortController()
              return fetch(data.pitStops, {
                cache: 'force-cache',
                mode: 'same-origin',
                signal: controller.signal
              })
            }

            // populate data
            if (json.MRData.RaceTable.Races.length >= 1) {
              this.racePitStops = json.MRData.RaceTable.Races[0].PitStops
            }

            // Unless null
            if (this.emptyObject(this.racePitStops)) {
              this.racePitStops = false
            }
          })
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.name === 'otherRaces' || from.name === 'otherRaces') {
        this.appFadeIn = ''
      }
    }
  }
}
</script>

<style scoped>
.app-fade-in-enter {
  opacity: 0;
}

.app-fade-in-enter-active {
  transition: opacity 0.6s ease-in;
}
</style>
