<template>
  <transition
    appear
    name="fade-in"
  >
    <section
      v-if="!raceDataIsFetching"
      :class="`race-information section section--color1`"
    >
      <header class="section-content__header">
        <h2 class="heading">Race Information</h2>
        <div class="section-content__icons">
          <!-- Map -->
          <button
            v-if="$navigatorOnline"
            :class="['section-content__icon', { active: mapActive }]"
            v-on:click="activateMap"
          >
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M424.473 301H311.258l58.96-94.078c13.563-21.512 20.735-46.383 20.735-71.922 0-74.438-60.562-135-135-135-74.441 0-135 60.563-135 135 0 25.54 7.168 50.41 20.711 71.887L200.648 301H87.43L0 512h511.902zm-76.934 120h94.184L467 482H355.316zm-22.469 61H186.684l7.582-61h123.03zm104.223-91h-85.578l-7.649-60h68.364zm-278.34-256c0-57.898 47.102-105 105-105 57.895 0 105 47.102 105 105 0 19.867-5.57 39.203-16.133 55.953l-88.867 141.8-88.89-141.831c-10.54-16.719-16.11-36.055-16.11-55.922zm105 254.246L292.457 331h13.367l7.649 60h-115.48l7.46-60h13.996zM107.473 331h67.75l-7.461 60H82.609zM70.18 421h93.855l-7.586 61H44.902zm0 0"/><path d="M330.953 135c0-41.355-33.644-75-75-75s-75 33.645-75 75 33.645 75 75 75 75-33.645 75-75zm-120 0c0-24.813 20.188-45 45-45s45 20.188 45 45-20.187 45-45 45-45-20.188-45-45zm0 0"/></svg>
            <span class="section-content__icon-text">Map</span>
          </button>
          <!-- Circuit map -->
          <button
            v-if="wikiData.circuitImgUrl && $navigatorOnline"
            :class="['section-content__icon', { active: circuitActive }]"
            v-on:click="activateCircuit"
          >
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M378.828 0H162.172C89.293 0 30 59.293 30 132.172V212H0v30h150v-30h-30v-79.828C120 108.918 138.918 90 162.172 90h216.656C402.23 90 422 109.313 422 132.172v62c0 18.18-11.59 34.254-28.773 39.976l-61.86 20.297A132.014 132.014 0 00241 379.828C241 403.082 222.082 422 198.828 422h-36.656C138.918 422 120 403.082 120 379.828V302h30v-30H0v30h30v77.828C30 452.707 89.293 512 162.172 512h36.656C271.707 512 331 452.707 331 379.828c0-18.18 11.59-34.254 28.773-39.976l61.86-20.297A132.014 132.014 0 00512 194.172v-62C512 59.262 451.844 0 378.828 0zM482 194.172c0 44.039-28.074 82.988-69.79 96.902l-61.855 20.293A72.071 72.071 0 00301 379.828C301 436.164 255.164 482 198.828 482h-36.656C105.836 482 60 436.164 60 379.828V302h30v77.828C90 419.621 122.375 452 162.172 452h36.656C238.625 452 271 419.621 271 379.828c0-44.039 28.074-82.988 69.79-96.902l61.855-20.293A72.071 72.071 0 00452 194.172v-62C452 92.418 418.766 60 378.828 60H162.172C122.375 60 90 92.379 90 132.172V212H60v-79.828C60 75.836 105.836 30 162.172 30h216.656C435.718 30 482 75.836 482 132.172zm0 0"/></svg>
            <span class="section-content__icon-text">Circuit</span>
          </button>
          <!-- Race info -->
          <button
            v-if="$navigatorOnline"
            :class="['section-content__icon', { active: raceActive }]"
            v-on:click="activateRace"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.7 426.7" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M192 192h43v128h-43z"/><path d="M213 0a213 213 0 100 427 213 213 0 000-427zm0 384a171 171 0 111-342 171 171 0 01-1 342z"/><path d="M192 107h43v42h-43z"/></svg>
            <span class="section-content__icon-text">Info</span>
          </button>
        </div>
      </header>
      <div :class="[
          'race-information__wrap',
          { 'overflow-active': circuitActive },
        ]">
        <!-- Race info -->
        <transition-group
          name="info"
          tag="div"
        >
          <div
            v-if="raceActive && !raceDataIsFetching"
            class="race-information__inner race-information__race-info"
            key="infoRace"
          >
            <div
              v-if="!wikiDataIsFetching"
              class="race-information__race-info-inner"
            >
              <p v-if="wikiData.firstHeld">
                <strong>First held:</strong>
                <span>{{ wikiData.firstHeld }}</span>
              </p>
              <p v-if="wikiData.timesHeld">
                <strong>Times held:</strong>
                <span>{{ wikiData.timesHeld }}</span>
              </p>
              <p v-if="wikiData.laps">
                <strong>Laps:</strong>
                <span>{{ wikiData.laps }}</span>
              </p>
              <p v-if="wikiData.circuitLength">
                <strong>Circuit length:</strong>
                <span>{{ wikiData.circuitLength }}</span>
              </p>
              <p v-if="wikiData.raceLength">
                <strong>Race length:</strong>
                <span>{{ wikiData.raceLength }}</span>
              </p>
              <p v-if="wikiData.mostWinsDrivers">
                <strong>Most Wins (Drivers):</strong>
                <span>{{ wikiData.mostWinsDrivers }}</span>
              </p>
              <p v-if="wikiData.mostWinsConstructors">
                <strong>Most Wins (Constructors):</strong>
                <span>{{ wikiData.mostWinsConstructors }}</span>
              </p>
            </div>
            <transition name="fade-in-weather">
              <img
                v-if="getWeatherImgUrl()"
                class="race-information__weather-icon-svg"
                svg-inline
                :src="getWeatherImgUrl()"
                :alt="`${weatherImg} weather icon`"
              />
            </transition>
            <div
              v-if="!wikiRace"
              class="spinner spinner--app"
            >
              <div class="spinner__inner"></div>
            </div>
          </div>

          <!-- Circuit map -->
          <div
            v-if="wikiData.circuitImgUrl && circuitActive && $navigatorOnline"
            class="race-information__inner race-information__circuit"
            key="infoCircuit"
          >
            <div class="race-information__circuit-img-wrap">
              <img
                class="race-information__circuit-img"
                :src="wikiData.circuitImgUrl"
                alt="Circuit layout"
                ref="circuitImg"
              />
            </div>
            <svg
              v-if="touchDevice"
              class="race-information__zoom-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.3 512.3"
            >
              <path d="M203 84c-1-6-6-10-12-9L42 94l21-35c3-5 1-12-4-15s-12-1-15 4l-32 53a11 11 0 00-1 8v1l1 1v2h1l1 1 43 43c2 2 4 3 7 3s6-1 8-3c4-4 4-11 0-15l-28-27 149-19c6-1 10-6 10-12zM501 52v-2l-1-1v-1l-1-2h-1L456 3c-5-4-11-4-15 0-5 4-5 11 0 15l27 27-160 19c-6 1-10 6-9 12 0 5 5 9 10 9h2l159-19-20 35a11 11 0 009 16c3 0 7-2 9-5l32-53c1-2 2-4 1-7z" />
              <path d="M414 344c-5-5-11-9-18-11a41 41 0 00-21-64 41 41 0 00-21-64l38-37a41 41 0 00-58-58L189 255l-14-86c-3-23-23-41-47-41-17 0-32 15-32 32v201c0 32 12 61 35 83l33 34a117 117 0 00162 4l88-80c16-16 16-42 0-58zm-15 42l-88 80a96 96 0 01-132-3l-33-34a95 95 0 01-28-68V160c0-6 4-11 10-11 13 0 24 10 26 23l17 107a11 11 0 0018 6l160-160c8-8 20-8 28 0 7 8 7 20 0 28L270 259c-4 4-4 11 0 15 4 5 11 5 15 0l44-43c7-8 20-8 27 0 8 7 8 20 0 27l-38 38-5 6c-5 4-5 11 0 15s11 4 15 0l5-5 17-17c7-8 20-8 28 0 7 7 7 20 0 27l-17 17-6 5v1c-4 4-4 10 0 15s11 4 15 0l1-1c8-8 20-8 28 0 7 7 7 20 0 27z" />
            </svg>

            <svg
              v-else
              class="race-information__zoom-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 42 60"
            >
              <g fill-rule="nonzero">
                <path d="M31 0H11C4.9278.0072.0072 4.9278 0 11v34c.0094 8.2804 6.7196 14.9906 15 15h12c8.2804-.0094 14.9906-6.7196 15-15V11C41.9928 4.9278 37.0722.0072 31 0zm-4 58H15c-7.1763-.0083-12.9917-5.8237-13-13V26h15v1c0 2.2091 1.7909 4 4 4s4-1.7909 4-4v-1h15v19c-.0083 7.1763-5.8237 12.9917-13 13zm-4-31c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-4c0-1.1046.8954-2 2-2s2 .8954 2 2zm17-3H25v-1c0-2.2091-1.7909-4-4-4s-4 1.7909-4 4v1H2V11c.0055-4.9683 4.0317-8.9945 9-9h20c4.9683.0055 8.9945 4.0317 9 9z" />
                <path d="M37 41c-.5523 0-1 .4477-1 1v3c-.0055 4.9683-4.0317 8.9945-9 9h-5c-.5523 0-1 .4477-1 1s.4477 1 1 1h5c6.0722-.0072 10.9928-4.9278 11-11v-3c0-.5523-.4477-1-1-1zM6 28H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h1c.5523 0 1-.4477 1-1s-.4477-1-1-1zM13 28h-3c-.5523 0-1 .4477-1 1s.4477 1 1 1h3c.5523 0 1-.4477 1-1s-.4477-1-1-1zM37 35c-.5523 0-1 .4477-1 1v2c0 .5523.4477 1 1 1s1-.4477 1-1v-2c0-.5523-.4477-1-1-1zM21.707 3.293c-.3905-.3904-1.0235-.3904-1.414 0l-3 3a1 1 0 001.414 1.414L21 5.414l2.293 2.293a1 1 0 001.414-1.414z" />
                <path d="M21.707 8.293c-.3905-.3904-1.0235-.3904-1.414 0l-3 3a1 1 0 001.414 1.414L21 10.414l2.293 2.293a1 1 0 001.414-1.414z" />
                <path d="M21.707 13.293c-.3905-.3904-1.0235-.3904-1.414 0l-3 3a1 1 0 001.414 1.414L21 15.414l2.293 2.293a1 1 0 001.414-1.414zM21.707 46.707l3-3a1 1 0 00-1.414-1.414L21 44.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3c.3905.3904 1.0235.3904 1.414 0z" />
                <path d="M20.293 41.707c.3905.3904 1.0235.3904 1.414 0l3-3a1 1 0 00-1.414-1.414L21 39.586l-2.293-2.293a1 1 0 00-1.414 1.414z" />
                <path d="M20.293 36.707c.3905.3904 1.0235.3904 1.414 0l3-3a1 1 0 00-1.414-1.414L21 34.586l-2.293-2.293a1 1 0 00-1.414 1.414z" />
              </g>
            </svg>
          </div>
          <!-- Map -->
          <div
            v-if="mapActive && $navigatorOnline"
            :class="[
              'map-wrapper race-information__inner',
              { 'filter-inactive': mapFilterInactive },
            ]"
            key="infoMap"
          >
            <l-map
              :zoom="zoom"
              :center="[race.Circuit.Location.lat, race.Circuit.Location.long]"
            >
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              ></l-tile-layer>
            </l-map>
            <button
              class="race-information__map-colour-btn"
              aria-label="Active map Light/Dark mode"
              v-on:click="mapFilterSwitch()"
            >
              <svg
                fill="#ff0000"
                xmlns="http://www.w3.org/2000/svg"
                width="368.554"
                height="368.554"
                viewBox="0 0 368.554 368.554"
              >
                <path d="M146.167 87.108c25.956-10.18 54.321-9.642 79.871 1.512 52.745 23.027 76.923 84.672 53.896 137.417a7.5 7.5 0 0013.747 6.001c26.336-60.325-1.317-130.83-61.642-157.166-29.225-12.758-61.666-13.372-91.349-1.729-29.685 11.643-53.059 34.148-65.817 63.371-26.336 60.326 1.316 130.83 61.642 157.166 15.303 6.681 31.502 10.03 47.729 10.03 14.53 0 29.083-2.687 43.03-8.072a7.5 7.5 0 00-5.403-13.993c-25.812 9.968-53.994 9.36-79.354-1.712-52.746-23.027-76.923-84.672-53.896-137.417 11.154-25.551 31.591-45.228 57.546-55.408zM303.974 53.974L280.62 77.327a7.5 7.5 0 005.303 12.803 7.474 7.474 0 005.303-2.197L314.58 64.58a7.5 7.5 0 000-10.606 7.5 7.5 0 00-10.606 0zM77.327 280.62l-23.354 23.354a7.5 7.5 0 005.303 12.803 7.474 7.474 0 005.303-2.197l23.354-23.354a7.5 7.5 0 000-10.606 7.5 7.5 0 00-10.606 0zM361.054 176.777h-33.027a7.5 7.5 0 000 15h33.027a7.5 7.5 0 000-15zM40.527 176.777H7.5a7.5 7.5 0 000 15h33.027a7.5 7.5 0 000-15zM291.227 280.62a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l23.354 23.354c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197a7.5 7.5 0 000-10.606l-23.354-23.354zM77.327 87.934c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197a7.5 7.5 0 000-10.606L64.58 53.974a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l23.353 23.354zM184.277 320.527a7.5 7.5 0 00-7.5 7.5v33.027a7.5 7.5 0 0015 0v-33.027a7.5 7.5 0 00-7.5-7.5zM184.277 48.027a7.5 7.5 0 007.5-7.5V7.5a7.5 7.5 0 00-15 0v33.027a7.5 7.5 0 007.5 7.5z" />
              </svg>
            </button>
          </div>
        </transition-group>
      </div>
    </section>
  </transition>
</template>

<script>
// import picomatch from 'picomatch'
import {
  LMap,
  LTileLayer
} from 'vue2-leaflet'
import EventBus from '@/event-bus/event-bus.js'
import Panzoom from 'panzoom'

export default {
  name: 'race-information',
  props: {
    race: [Object, Boolean],
    qualifying: [Object, Boolean],
    qualifyingResults: [Array, Boolean],
    raceDataIsFetching: Boolean
  },
  components: {
    LMap,
    LTileLayer
  },
  data () {
    return {
      raceActive: true,
      circuitActive: false,
      mapActive: false,
      panZoomActive: false,
      error: false,
      zoom: 15,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      raceStr: '',
      circuitStr: '',
      wikiDataIsFetching: true,
      wikiCircuit: '',
      wikiRace: '',
      mapFilterInactive: false,
      wikiData: {
        circuitLength: '',
        firstHeld: '',
        laps: '',
        mostWinsConstructors: '',
        mostWinsDrivers: '',
        raceLength: '',
        timesHeld: '',
        weather: '',
        circuitgetFlagImgUrl: '',
        circuitImgData: '',
        circuitImgUrl: ''
      },
      weatherArr: '',
      weatherImg: '',
      touchDevice: false
    }
  },
  created () {
    EventBus.$on('wikiDataPopulate', (payload) => {
      // True or false for asc desc sorting
      this.fetchData(payload)

      // Reset so it doens't run multiple times upon route change
      EventBus.$off('wikiDataPopulate')
    })
  },
  mounted () {
    if (this.$touchDevice) {
      this.touchDevice = true
    }
  },
  methods: {
    fetchData (controller) {
      this.circuitStr = this.race.url.substr(this.race.url.lastIndexOf('/') + 6)
      this.raceStr = this.race.url.substr(this.race.url.lastIndexOf('/') + 1)

      const obj = {
        circuitUrl: `https://en.wikipedia.org/w/api.php?action=parse&section=0&prop=text&page=${this.circuitStr}&format=json&origin=*`,
        raceUrl: `https://en.wikipedia.org/w/api.php?action=parse&section=0&prop=text&page=${this.raceStr}&format=json&origin=*`
      }

      let iterations = 0

      for (const key of Object.keys(obj)) {
        const val = obj[key]
        fetch(val, {
          cache: 'force-cache'
        })
          // eslint-disable-next-line no-console
          .catch((err) => console.error('Fetch request for Wikipedia data failed: ', err))
          .then((response) =>
            response.json()
              .catch(err => {
                // eslint-disable-next-line no-console
                console.error(`'${err}' happened!`)
                return {}
              }))
          .then((json) => {
            if (json.status === 504) {
              // Abort fetch request before it has completed then use cached data
              controller.abort()
              controller = new AbortController()
              return fetch(val, {
                cache: 'force-cache',
                mode: 'same-origin',
                signal: controller.signal
              })
            }

            if (key === 'raceUrl') {
              // Clear any previously stored data
              this.wikiRace = false

              // Parse
              this.wikiRace = json.parse.text['*']

              // Remove all line breaks
              this.wikiRace = this.wikiRace.replace(/r?\n|\r/g, '')

              // Get circuit image before removing markup, but not if Indy500 (not supported)
              if (!this.raceStr.includes('Indianapolis')) {
                let circuitgetFlagImgUrl = /class="infobox-image"><a href="\/wiki\/File:(.*?).(svg|png|jpg|gif|bmp)/
                circuitgetFlagImgUrl = circuitgetFlagImgUrl.exec(this.wikiRace)
                if (circuitgetFlagImgUrl) {
                  circuitgetFlagImgUrl = `${circuitgetFlagImgUrl[1]}.${circuitgetFlagImgUrl[2]}`
                  this.wikiData.circuitgetFlagImgUrl = circuitgetFlagImgUrl
                }
              }

              // Remove all HTML markup
              this.wikiRace = this.wikiRace.replace(/(<([^>]+)>)/gi, '')

              // Remove non breaking spaces
              this.wikiRace = this.wikiRace.replace(/&#160;/g, '')
            } else {
              this.wikiCircuit = false
              this.wikiCircuit = json.parse.text['*']
              this.wikiCircuit = this.wikiCircuit.replace(/r?\n|\r/g, '')
              this.wikiCircuit = this.wikiCircuit.replace(/(<([^>]+)>)/gi, '')
              this.wikiCircuit = this.wikiCircuit.replace(/&#160;/g, '')
            }
          })
          .then(() => {
            // Run template data population on 2nd/final iteration
            if (iterations === 1) {
              this.wikiDataPopulate()
              // Don't run if Indy500 (not supported)
              if (!this.raceStr.includes('Indianapolis')) {
                this.circuitImgFetch(controller)
              }
            }
            iterations++
          })
        // }
      }
    },
    mapFilterSwitch () {
      !this.mapFilterInactive ? this.mapFilterInactive = true : this.mapFilterInactive = false
    },
    circuitImgFetch (controller) {
      if (!this.wikiData.circuitgetFlagImgUrl) return
      // Fetch Json from the circuit image page
      const val = `//www.mediawiki.org/w/api.php?action=query&titles=File:${this.wikiData.circuitgetFlagImgUrl}&action=query&prop=imageinfo&iiprop=url&format=json&origin=*`
      fetch(val, {
        cache: 'force-cache'
      })
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for Wikipedia data failed: ', err))
        .then((response) =>
          response.json()
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error(`'${err}' happened!`)
              return {}
            }))
        .then((json) => {
          if (json.status === 504) {
            // Abort fetch request before it has completed then use cached data
            controller.abort()
            controller = new AbortController()
            return fetch(val, {
              cache: 'force-cache',
              mode: 'same-origin',
              signal: controller.signal
            })
          }

          // Get json
          let pagesObj = json.query.pages

          // Get child property name, doing this because uncertain if the value sometimes differs
          pagesObj = Object.getOwnPropertyNames(pagesObj)

          const pagesObjLength = pagesObj.length
          this.childVal = () => {
            for (const k in pagesObj) {
              if (pagesObjLength) {
                // if statement to check length so it only runs on the final iteration
                return pagesObj[k]
              }
            }
          }

          // Use child prop to get image url
          this.wikiData.circuitImgUrl = json.query.pages[this.childVal()].imageinfo[0].url

          // Signal to App.vue that all promises are complete
          EventBus.$emit('dataIsFetching', false)
        })
    },
    wikiDataPopulate () {
      // Circuit
      // Match first 4 digits occuring after "First held"
      let firstHeld = /First held([\0-9]{4})/
      firstHeld = firstHeld.exec(this.wikiCircuit)
      if (firstHeld) {
        firstHeld = firstHeld[1]
        this.wikiData.firstHeld = firstHeld
      }
      // Match any digits occuring after "Number of times held" and before "First held"
      let timesHeld = /Number of times held([0-9]{2})/
      timesHeld = timesHeld.exec(this.wikiCircuit)
      if (timesHeld) {
        timesHeld = timesHeld[1]
        this.wikiData.timesHeld = timesHeld
      }
      // Match string between "Scheduled distance" and " laps"
      let scheduledLaps = /Scheduled distance(.*?) laps/
      scheduledLaps = scheduledLaps.exec(this.wikiRace)
      if (scheduledLaps) {
        scheduledLaps = scheduledLaps[1]
        scheduledLaps = scheduledLaps.substring(0, 2)
        this.wikiData.laps = scheduledLaps
      } else {
        // Match string between "Distance" and " laps"
        let laps = /Distance(.*?) laps/
        laps = laps.exec(this.wikiRace)
        if (laps) {
          laps = laps[1]
          laps = laps.substring(0, 2)
          this.wikiData.laps = laps
        }
      }
      // Match string between "Scheduled distanceXX laps" and "mi)"
      let scheduledRaceLength = /Scheduled distance[0-9]{2} laps, (.*?) mi/
      scheduledRaceLength = scheduledRaceLength.exec(this.wikiRace)
      if (scheduledRaceLength) {
        scheduledRaceLength = scheduledRaceLength[1]
        scheduledRaceLength = scheduledRaceLength.substring(0, 5)
        let scheduledRaceLengthKm = scheduledRaceLength / 0.62137
        scheduledRaceLengthKm = scheduledRaceLengthKm.toFixed(3)
        this.wikiData.raceLength = `${scheduledRaceLengthKm} km (${scheduledRaceLength} mi)`
      } else {
        // Match string between "Scheduled distanceXX laps" and "mi)"
        let raceLength = /Distance[0-9]{2} laps, (.*?) mi/
        raceLength = raceLength.exec(this.wikiRace)
        if (raceLength) {
          raceLength = raceLength[1]
          raceLength = raceLength.substring(0, 7)
          let raceLengthKm = raceLength / 0.62137
          raceLengthKm = raceLengthKm.toFixed(3)
          this.wikiData.raceLength = `${raceLengthKm} km (${raceLength} mi)`
        }
      }
      let circuitLength = /Course length(.*)Distance/
      circuitLength = circuitLength.exec(this.wikiRace)
      if (circuitLength) {
        circuitLength = circuitLength[1]
        circuitLength = circuitLength.substring(0, 5)
        let circuitLengthKm = circuitLength / 0.62137
        circuitLengthKm = circuitLengthKm.toFixed(3)
        this.wikiData.circuitLength = `${circuitLengthKm} km (${circuitLength} mi)`
      }
      let mostWinsDrivers = /Most wins \(drivers\)(.*)Most wins \(constructors\)/
      mostWinsDrivers = mostWinsDrivers.exec(this.wikiCircuit)
      if (mostWinsDrivers) {
        mostWinsDrivers = mostWinsDrivers[1]
        this.wikiData.mostWinsDrivers = mostWinsDrivers
      }
      let mostWinsConstructors = /Most wins \(constructors\) (.*)Circuit length/
      mostWinsConstructors = mostWinsConstructors.exec(this.wikiCircuit)
      if (mostWinsConstructors) {
        mostWinsConstructors = mostWinsConstructors[1]
        this.wikiData.mostWinsConstructors = mostWinsConstructors
      }
      let weather = /Weather(.*)Pole/
      weather = weather.exec(this.wikiRace)
      if (weather) {
        weather = weather[1]
        this.wikiData.weather = weather
      }
      this.addWeatherImg()
    },
    addWeatherImg () {
      if (this.wikiData.weather) {
        const str = this.wikiData.weather.toLowerCase()
        // These all trump one another
        // Fog
        if (str.includes('fog')) {
          this.weatherImg = 'fog'
        }
        // Sun
        if (str.includes('clear') || str.includes('sunny') || str.includes('dry') || str.includes('hot') || str.includes('reaching up to')) {
          this.weatherImg = 'sun'
        }
        // Sun and cloud
        if (str.includes('sun') && str.includes('cloud')) {
          this.weatherImg = 'sun-and-cloud'
        }
        // Cloudy
        if (str.includes('overcast') || str.includes('cloud')) {
          this.weatherImg = 'cloud'
        }
        // Rain
        if (str.includes('rain') || str.includes('wet')) {
          this.weatherImg = 'rain'
        }
        // Rain and sun
        if (str.includes('rain') && str.includes('sun')) {
          this.weatherImg = 'rain-and-clouds'
        }
        // Snow
        if (str.includes('snow')) {
          this.weatherImg = 'snow'
        }
        // thunder
        if (str.includes('thunder') || str.includes('lightning')) {
          this.weatherImg = 'thunder'
        }
        // Haze
        if (str.includes('haze') || str.includes('hazy')) {
          this.weatherImg = 'haze'
        }
      }
      this.wikiDataIsFetching = false
    },
    activateMap () {
      this.mapFilterInactive = false
      this.raceActive = false
      this.circuitActive = false
      this.mapActive = true

      // Emitted to App.vue
      EventBus.$emit('overflowState', this.circuitActive)
    },
    activateRace () {
      this.mapActive = false
      this.circuitActive = false
      this.raceActive = true
      EventBus.$emit('overflowState', this.circuitActive)
    },
    activateCircuit () {
      this.mapActive = false
      this.raceActive = false
      this.circuitActive = true
      this.panZoomActive = true

      // Init Panzoom plugin
      this.$nextTick(() => {
        if (this.circuitActive) Panzoom(this.$refs.circuitImg)
      })
      EventBus.$emit('overflowState', this.circuitActive)
    },
    getFlagImgUrl (i) {
      const fallback = require.context('@/assets/img/', false, /.png$/)
      const flagImages = require.context('@/assets/img/flags/', false, /.svg$/)
      let flagImage = ''
      try {
        flagImage = flagImages(`./${this.race.Circuit.Location.country}.svg`)
      } catch (error) {
        flagImage = fallback('./fallback.png')
      }
      return flagImage
    },
    getWeatherImgUrl (i) {
      const weatherImages = require.context('@/assets/img/svg/icons/weather/', false, /.svg$/)
      let weatherImage
      try {
        weatherImage = weatherImages(`./${this.weatherImg}.svg`)
      } catch (error) {
        weatherImage = null
      }
      return weatherImage
    }
  }
}
</script>

<style scoped>
.fade-in-enter {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
}

.fade-in-enter-active {
  transition: opacity 0.3s ease-in 0.25s,
    transform 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.25s;
}

.fade-in-info-enter {
  opacity: 0;
}

.fade-in-info-enter-active {
  transition: opacity 0.1s ease-in;
}

.fade-in-weather-enter {
  opacity: 0;
  transform: scale3d(0.95, 0.95, 0.95);
}

.fade-in-weather-enter-active {
  transition: opacity 0.15s ease-in,
    transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.race-information__inner {
  display: flex;
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}

.info-enter,
.info-leave-to {
  opacity: 0;
  transform: scale3d(0.95, 0.95, 0.95);
}

.map-wrapper.info-enter,
.map-wrapper.info-leave-to {
  transition: none;
}

.race-information__circuit.info-leave-to {
  transform-origin: center top;
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}

.map-wrapper.info-enter,
.map-wrapper.info-leave-to {
  transform: scale3d(1.05, 1.05, 1.05);
}

.info-leave-active {
  overflow: hidden;
  position: absolute;
}

.info-leave-active {
  overflow: hidden;
}
</style>
