<template>
  <div class="race-wrapper other-races">
    <!-- Latest race data needed so we can do some filtering on the race select component -->
    <RaceHeader
      :seasons="seasons"
      :race="race"
      :raceDataIsFetching="raceDataIsFetching"
      :latestRaceData="latestRaceData"
      :latestRaceDataFetching="latestRaceDataFetching"
    ></RaceHeader>
    <template>
      <RaceInformation
        v-if="!raceDataIsFetching"
        :raceDataIsFetching="raceDataIsFetching"
        :race="race"
      ></RaceInformation>
    </template>
    <RaceResults
      :raceResults="raceResults"
      :racePitStops="racePitStops"
    ></RaceResults>
    <template
      v-if="qualifyingResults && race && race.Circuit && race.Circuit.Location"
    >
      <QualifyingResults
        v-if="!qualifyingDataIsFetching && !raceDataIsFetching"
        :seasons="seasons"
        :qualifyingResults="qualifyingResults"
        :qualifying="qualifying"
      ></QualifyingResults>
    </template>
    <ShowSeasons
      v-if="
        !qualifyingDataIsFetching &&
        !raceDataIsFetching &&
        race.slug !== 'indianapolis_500'
      "
    ></ShowSeasons>
    <DriverStandings></DriverStandings>
    <ConstructorStandings></ConstructorStandings>
    <SiteFooter
      v-if="!qualifyingDataIsFetching && !raceDataIsFetching"
    ></SiteFooter>
  </div>
</template>

<script>
import RaceHeader from '@/components/RaceHeader'
import RaceInformation from '@/components/RaceInformation'
import RaceResults from '@/components/RaceResults'
import QualifyingResults from '@/components/QualifyingResults'
import SiteFooter from '@/components/SiteFooter'
import DriverStandings from '@/components/DriverStandings'
import ConstructorStandings from '@/components/ConstructorStandings'
import ShowSeasons from '@/components/elements/ShowSeasons'
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'other-races',
  props: {
    qualifyingResults: [Array, Boolean],
    racePitStops: [Array, Boolean],
    raceResults: [Array, Boolean],
    race: [Object, Boolean],
    qualifying: [Object, Boolean],
    raceDataIsFetching: Boolean,
    qualifyingDataIsFetching: Boolean,
    seasons: Array
  },
  components: {
    RaceHeader,
    RaceInformation,
    RaceResults,
    QualifyingResults,
    ShowSeasons,
    DriverStandings,
    ConstructorStandings,
    SiteFooter
  },
  data () {
    return {
      latestRaceData: {},
      latestRaceDataFetching: true
    }
  },
  created () {
    const controller = new AbortController()

    this.options = {
      cache: 'force-cache',
      method: 'GET',
      signal: controller.signal
    }

    // Ergast data
    const season = this.$route.params.season
    const round = this.$route.params.round

    this.data = {
      results: `https://ergast.com/api/f1/${season}/${round}/results.json`,
      qualifying: `https://ergast.com/api/f1/${season}/${round}/qualifying.json`,
      pitStops: `https://ergast.com/api/f1/${season}/${round}/pitstops.json`
    }

    // Emitted to App.vue
    EventBus.$emit('raceRequest', this.data, this.options, controller)

    // Get the latest race again so we can determine which is the latest
    // race and year that can be shown on the race select component
    this.date = new Date()
    this.day = this.date.getDay()
    this.hours = this.date.getHours()

    // Used later to abort web request if it fails
    let controllerLatestRace = new AbortController()

    if ((this.day === 7 && this.hours > 9 && this.hours < 24) || (this.day === 1 && this.hours < 9)) {
      // Use cache if race has just ended
      this.latestRaceDataOptions = {
        cache: 'no-store',
        method: 'GET',
        signal: controllerLatestRace.signal
      }
    } else {
      this.latestRaceDataOptions = {
        cache: 'force-cache',
        method: 'GET',
        signal: controllerLatestRace.signal
      }
    }
    setTimeout(() => {
      // Stagger so we're abiding to the API's T+C's (No more than 4 polls per second)
      fetch('https://ergast.com/api/f1/current/last/results.json', this.latestRaceDataOptions)
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for Latest Race Results data failed: ', err))
        .then((response) => response.json()
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(`'${err}' happened!`)
            return {}
          }))
        .then((json) => {
          if (json.status === 504) {
            // Abort fetch request before it has completed then use cached data
            controllerLatestRace.abort()
            controllerLatestRace = new AbortController()
            return fetch('https://ergast.com/api/f1/current/last/results.json', {
              cache: 'force-cache',
              mode: 'same-origin',
              signal: controllerLatestRace.signal
            })
          }

          if (json.MRData.RaceTable.Races.length > 0) {
            //  If not empty then set data
            this.latestRaceData = json.MRData.RaceTable.Races[0]
            this.latestRaceDataFetching = false
          } else {
            this.latestRaceData = false
          }
        })
    }, 1100)
  }
}
</script>
