<template>
  <transition appear name="fade-in">
    <section
      v-if="!driverStandingsDataFetching && driverStandingsData"
      class="driver-standings section section--color4"
    >
      <header class="section-content__header">
        <h2 class="heading">{{ this.tableHeading }}</h2>
      </header>
      <div class="section__content">
        <div class="section__table-wrapper">
          <div class="section-table section-table--driver-standings active">
            <DriverStandingsTable
              :driverStandingsData="driverStandingsData"
            ></DriverStandingsTable>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import DriverStandingsTable from '@/components/tables/DriverStandingsTable'
import EventBus from '@/event-bus/event-bus.js'

export default {
  name: 'driver-standings',
  components: {
    DriverStandingsTable
  },
  data () {
    return {
      driverStandingsData: {},
      driverStandingsDataFetching: true,
      tableHeading: 'Driver Standings'
    }
  },
  created () {
    EventBus.$on('activateDriverStandings', () => {
      this.FetchData()
    })
  },
  mounted () {
    this.driverStandingsData = false
    this.driverStandingsDataFetching = true
  },
  methods: {
    FetchData () {
      let controller = new AbortController()

      if (this.$route.name === 'latestRace') {
        this.request = 'https://ergast.com/api/f1/current/driverStandings.json'
        this.options = { method: 'GET', signal: controller.signal }
      } else if (this.$route.name === 'otherRaces') {
        const season = this.$route.params.season
        const round = this.$route.params.round
        this.request = `https://ergast.com/api/f1/${season}/${round}/driverStandings.json`
        this.options = { cache: 'force-cache', method: 'GET', signal: controller.signal }
      }

      fetch(this.request, this.options)
        // eslint-disable-next-line no-console
        .catch((err) => console.error('Fetch request for driver standings data failed: ', err))
        .then((response) => response.json()
          // eslint-disable-next-line no-console
          .catch(err => { console.error(`'${err}' happened!`); return {} }))
        .then((json) => {
          if (json.status === 504 && this.$route.name === 'otherRaces') {
            // Abort fetch request before it has completed then use cached data
            controller.abort()
            controller = new AbortController()
            return fetch(this.request, { cache: 'force-cache', mode: 'same-origin', signal: controller.signal })
          }

          // Clear any previously stored data
          this.driverStandingsData = false

          // Set data
          this.driverStandingsData = json.MRData.StandingsTable.StandingsLists[0]
          this.driverStandingsDataFetching = false

          // Emitted to ShowSeasons.vue
          EventBus.$emit('seasonsLoaded')

          // Emitted to ConstructorStandings.vue
          EventBus.$emit('activateConstructorStandings')
          EventBus.$off('activateDriverStandings')
        })
    }
  }
}
</script>

<style scoped>
.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
  transform: translate3d(0, 1.5rem, 0);
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.25s,
    transform 0.9s cubic-bezier(0.23, 1, 0.32, 1) 0.25s;
}
</style>
